import { defineStore } from "pinia";
import { AdminState } from "@/stores/statetypes";
import { HttpResponse } from "@/utils/networking/interfaces";
import { fetchGet, fetchPut } from "@/utils/networking/httpclient";
import Minimap from "@/models/minimap";
import { MinimapEnums } from "@/models/minimapEnums";
import { EmailRequest } from "@/models/emailRequest";
import { useSessionStore } from "@/stores/session";
import { JupiterStatusType } from "@/models/jupiterstatus";

export const useAdminStore = defineStore("admin", {
  state: (): AdminState => {
    return {
      minimaps: [],
      minimapsSpS: [],
      minimapURL: "",
      oppumpedeMaengderAnlaeg: [],
      oppumpedeMaengderBoringIndtag: [],
      valideringPejlinger: [],
      tilsynsskabeloner: [],
      tilsynsskabelonerBookmarks: [],
      kontrolpunktsskabeloner: [],
      kontrolpunktsemner: [],
      jupiterStatusser: [],
      jupiterStatusType: new JupiterStatusType()
    };
  },
  actions: {
    async fetchMinimap(visningssted: MinimapEnums | string): Promise<string> {
      const kundeId = getKundeId();
      const { status, data } = await fetchGet(`minimaps/forvisningssted/${kundeId}?visningssted=${visningssted}`);

      return data.minimapGuid;
    },

    async fetchMinimapUrl(): Promise<string> {
      const kundeId = getKundeId();
      const { status, data } = await fetchGet(`minimaps/minimapurl/${kundeId}`);

      this.minimapURL = data;

      return data;
    },

    //Validering oppumpede mængder anlæg
    async fetchValideringOppumpedeMaengderAnlaeg(): Promise<HttpResponse> {
      const kundeId = getKundeId();
      const response = await fetchGet(`OppumpedeVandmaengderValidering/list/${kundeId}`);
      this.oppumpedeMaengderAnlaeg = response.data;
      return response;
    },
    async testMedianValideringOppumpedeMaengderAnlaeg(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/GetMedian/${kundeId}`, oppumpedeMaengderIder);
    },
    async godkendValideringOppumpedeMaengderAnlaeg(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/Godkend/${kundeId}`, oppumpedeMaengderIder);
    },
    async forkastValideringOppumpedeMaengderAnlaeg(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/Forkast/${kundeId}`, oppumpedeMaengderIder);
    },
    async fetchGodkendteOppumpedeMaengderAnlaeg(vandanlaegId: string): Promise<HttpResponse> {
      return await fetchGet(`OppumpedeVandmaengderValidering/list/godkendte/${vandanlaegId}`);
    },

    //Validering oppumpede mængder boring/indtag
    async fetchOppumpedeMaengderBoringIndtag(): Promise<HttpResponse> {
      const kundeId = getKundeId();
      const response = await fetchGet(`OppumpedeVandmaengderValidering/IndtagNiveau/list/${kundeId}`);

      this.oppumpedeMaengderBoringIndtag = response.data;
      return response;
    },
    async testMedianOppumpedeMaengderBoringIndtag(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/GetMedian/${kundeId}`, oppumpedeMaengderIder);
    },

    async godkendOppumpedeMaengderBoringIndtag(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/IndtagNiveau/Godkend/${kundeId}`, oppumpedeMaengderIder);
    },
    async forkastOppumpedeMaengderBoringIndtag(oppumpedeMaengderIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`OppumpedeVandmaengderValidering/IndtagNiveau/Forkast/${kundeId}`, oppumpedeMaengderIder);
    },
    async fetchGodkendteOppumpedeMaengderBoringIndtag(indtagId: string): Promise<HttpResponse> {
      return await fetchGet(`OppumpedeVandmaengderValidering/IndtagNiveau/list/godkendte/${indtagId}`);
    },

    //Validering pejlinger
    async fetchGodkendtePejlinger(indtagId: string): Promise<HttpResponse> {
      return await fetchGet(`Pejling/IndtagNiveau/list/godkendte/${indtagId}`);
    },
    async godkendValideringPejlinger(pejlingerIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`Pejling/Godkend/${kundeId}`, pejlingerIder);
    },
    async forkastValideringPejlinger(pejlingerIder: string[]): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`Pejling/Forkast/${kundeId}`, pejlingerIder);
    },

    //Send email til vandanlæg
    async fetchEmailVandanlaegSagsbehandler(emailRequest: EmailRequest): Promise<HttpResponse> {
      if (emailRequest.year < 1755 || emailRequest.year > 9999) {
        throw new Error("Året angivet er ikke validt");
      }

      const kundeId = getKundeId();
      let url = "Vandanlaeg/GetAnlaegMailIndberetningStatus?kundeId=" + kundeId;

      if (emailRequest.filter) {
        url += "&filter=" + emailRequest.filter;
      }
      if (emailRequest.year) {
        url += "&year=" + emailRequest.year;
      }
      if (emailRequest.sortByColumn) {
        url += "&SortByColumn=" + emailRequest.sortByColumn;
      }
      if (emailRequest.sortDirection) {
        url += "&SortDirection=" + emailRequest.sortDirection;
      }
      if (emailRequest.start) {
        url += "&Start=" + emailRequest.start;
      }
      if (emailRequest.take) {
        url += "&Take=" + emailRequest.take;
      }
      emailRequest.filteredColumns.forEach(column => {
        url += "&FilteredColumns=" + column;
      });

      const response = await fetchGet(url);

      return response;
    },

    async sendEmailVandanlaeg(virksomhedIds: any[] = [], year: any = "2020"): Promise<HttpResponse> {
      const kundeId = getKundeId();
      return await fetchPut(`Vandanlaeg/SendMailTilIndberettere/${kundeId}?year=${year}`, virksomhedIds);
    },

    async fetchKontrolpunktsskabeloner(): Promise<HttpResponse> {
      const kundeId = getKundeId();
      const response = await fetchGet(`Kontrolpunkt/templatedata/List/${kundeId}`);

      this.kontrolpunktsskabeloner = response.data;

      return response;
    },

    async fetchKontrolpunktsskabelonerTilsyn(virksomhedsType: string): Promise<HttpResponse> {
      const kundeId = getKundeId();
      const response = await fetchGet(`Kontrolpunkt/templatedata/List/${kundeId}/${virksomhedsType}`);

      this.kontrolpunktsskabeloner = response.data;

      return response;
    },

    async fetchKontrolpunktsskabelonEmner(templateId: string): Promise<HttpResponse> {
      const response = await fetchGet(`Kontrolpunkt/templatedata/${templateId}/emner`);
      this.kontrolpunktsemner = response.data;

      return response;
    },

    async fetchTilsynsskabeloner(): Promise<HttpResponse> {
      const kundeId = getKundeId();
      const response = await fetchGet(`TilsynRapport/templatedata/List/${kundeId}`);

      this.tilsynsskabeloner = response.data;

      return response;
    },

    async fetchTilsynsskabelonBookmarks(): Promise<HttpResponse> {
      const response = await fetchGet(`TilsynRapport/hentbookmarks`);
      this.tilsynsskabelonerBookmarks = response.data;

      return response;
    }
  },
  getters: {
    getMinimaps(): Minimap[] {
      return this.minimaps.map(minimap => {
        const newMinimap = minimap;

        const spsMinimap: any = this.minimapsSpS.find((minimap: any) => minimap.id === newMinimap.minimapGuid);

        if (spsMinimap) {
          newMinimap.navnTemp = minimap.navn;
          newMinimap.exists = false;
          newMinimap.version = spsMinimap.version;
        } else {
          newMinimap.navnTemp = "* " + minimap.navn;
          newMinimap.exists = true;
        }

        return newMinimap;
      });
    }
  }
});

function getKundeId() {
  const sessionStore = useSessionStore();
  return sessionStore.activeKunde.kundeId;
}
