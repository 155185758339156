import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import { ReportType } from "./ReportType";
import { formatDate } from "@/filters/mwTableFormatters";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import { nameof } from "@/utils/utils";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import JupiterHaandhaevelsesTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterHaandhaevelsesTypeFilter";

export class VandHaandhaevelseReportModel implements IReportModel<VandHaandhaevelseReportModel> {
  aarstal?: Number;
  maaned?: Number;
  startdato?: Date;
  deadline?: Date;
  anlaegJupiterId?: number;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  anlaegsNavn?: string;
  virksomhedsAdresse?: string;
  cvrNr?: string;
  pNr?: string;
  primaerVirksomhedsType?: string;
  primaerVirksomhedsTypeId?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegsNr?: string;
  anlaegsArt?: string;
  anlaegsArtId?: string;
  jupiterAktivStatus?: string;
  jupiterAktivStatusId?: string;
  haandhaevelsestype?: string;
  haandhaevelsestypeId?: string;
  titel?: string;
  sagsbehandler?: string;
  antal?: Number;

  get columnModel(): IMWReportColumn<VandHaandhaevelseReportModel>[] {
    return [
      { id: "aarstal", label: "Årstal", show: true },
      { id: "maaned", label: "Måned", show: true },
      { id: "startdato", label: "Dato", show: true, formatter: formatDate },
      { id: "deadline", label: "Frist", show: false, formatter: formatDate },
      { id: "anlaegJupiterId", label: "Jupiter ID", show: true },
      { id: "anlaegStartdato", label: "Anlaeg startdato", show: false, formatter: formatDate },
      { id: "anlaegSlutdato", label: "Anlaeg slutdato", show: false, formatter: formatDate },
      { id: "anlaegsNavn", label: "Anlægsnavn", show: false },
      { id: "virksomhedsAdresse", label: "Anlægsadresse", show: false },
      { id: "cvrNr", label: "CVR-nummer", show: false },
      { id: "pNr", label: "P-nummer", show: false },
      { id: "primaerVirksomhedsType", label: "Primær virksomhedstype", show: false },
      { id: "oevrigeVirksomhedstyper", label: "Øvrige virksomhedstyper", show: true, sort: false },
      { id: "anlaegsNr", label: "Anlægsnummer", show: false },
      { id: "anlaegsArt", label: "Anlægsart", show: false },
      { id: "jupiterAktivStatus", label: "Aktivstatus", show: false },
      { id: "haandhaevelsestype", label: "Håndhævelsestype", show: true },
      { id: "titel", label: "Titel", show: false },
      { id: "sagsbehandler", label: "Sagsbehandler", show: true },
      { id: "antal", label: "Antal", show: true }
    ];
  }

  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }

  get filters(): IMwTableFilter[] {
    return [
      new JupiterAktivStatusFilter(nameof<VandHaandhaevelseReportModel>("jupiterAktivStatusId"), "Aktiv status", "Aktiv status"),
      new JupiteranlaegsartFilter(nameof<VandHaandhaevelseReportModel>("anlaegsArtId"), "Anlægsart", "Anlægsart"),
      new JupiterHaandhaevelsesTypeFilter(
        nameof<VandHaandhaevelseReportModel>("haandhaevelsestypeId"),
        "Håndhævelsestype",
        "Håndhævelsestype"
      ),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<VandHaandhaevelseReportModel>("primaerVirksomhedsTypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new DateRangeFilter(nameof<VandHaandhaevelseReportModel>("startdato"), "DatoInterval", "Datointerval mellem"),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper")
    ];
  }

  get reportType(): ReportType {
    return ReportType.VandHaandhaevelse;
  }
}
