import { Koordinat } from "@/models/koordinat";
import moment from "moment";

const MIN_ISO_LENGTH = 8;

export function isValidDate(input: any) {
  return input && input.length > MIN_ISO_LENGTH && moment(input, moment.ISO_8601).isValid();
}
export function formatDate(key: any, value: any) {
  if (typeof value === "string" && isValidDate(value)) {
    return moment(value).locale("da");
  }
  return value;
}
export function formatDateToString(value: moment.Moment | null) {
  if (value) {
    return moment(value).locale("da").set("hour", 13).toISOString();
  }
  return "";
}

// Formats UTM32 EUREF89 coordinate
export function formatCoordinate(koordinat: Koordinat): Koordinat {
  if (koordinat.x != null) {
    koordinat.x = formatCoordinateValue(koordinat.x, 6);
  }
  if (koordinat.y != null) {
    koordinat.y = formatCoordinateValue(koordinat.y, 7);
  }
  return koordinat;
}

// converts a UTM32 EUREF89 coordiante value from using comma as decimal separator to period
export function formatCoordinateValue(value: number, coordinateLength: number): number {
  let valueString = value.toString();

  const hasDotThousandSeperator = valueString.slice(0, coordinateLength).includes(".");
  if (hasDotThousandSeperator) {
    valueString = valueString.replaceAll(".", "");
  }
  valueString = valueString.replace(",", ".");

  return Number.parseFloat(valueString);
}

// Replaces serverside newlines to html breaks in text.
export function formatNewLines(text: string){
  return text
    .replaceAll("\r", "")
    .replaceAll("\n", "<br/>")
}
