import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import { ReportType } from "./ReportType";
import { nameof } from "@/utils/utils";
import { formatDate } from "@/filters/mwTableFormatters";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import VandanlaegTilsynTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegTilsynTypeFilter";

export class VandTilsynReportModel implements IReportModel<VandTilsynReportModel> {
  titel?: string;
  anlaegsNummer?: string;
  udfoertDato?: Date;
  anlaegsNavn?: string;
  tilsynstype?: string;
  planlagtDato?: Date;
  sagsbehandler?: string;
  pNummer?: string;
  primaerVirksomhedstype?: string;
  antal?: number;
  maaned?: number;
  aarstal?: number;
  anlaegSlutdato?: Date;
  anlaegStartdato?: Date;
  anlaegsAdresse?: string;
  cvr?: string;
  anlaegsart?: string;
  aktivStatus?: string;
  jupiterId?: string;
  oeverigeVirksomhedstyper?: string;

  get columnModel(): IMWReportColumn<VandTilsynReportModel>[] {
    return [
      {
        id: "aarstal",
        label: "Årstal",
        show: true
      },
      {
        id: "maaned",
        label: "Måned",
        show: true
      },
      {
        id: "udfoertDato",
        label: "Udført dato",
        show: true,
        formatter: formatDate
      },
      {
        id: "planlagtDato",
        label: "Planlagt dato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegStartdato",
        label: "Anlæg startdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegSlutdato",
        label: "Anlæg slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegsNavn",
        label: "Anlægsnavn",
        show: false
      },
      {
        id: "anlaegsAdresse",
        label: "Anlægsadresse",
        show: false
      },
      {
        id: "cvr",
        label: "CVR-nummer",
        show: false
      },
      {
        id: "pNummer",
        label: "P-nummer",
        show: false
      },
      {
        id: "primaerVirksomhedstype",
        label: "Primær virksomhedstype",
        show: false
      },
      {
        id: "anlaegsNummer",
        label: "Anlægsnummer",
        show: false
      },
      {
        id: "anlaegsart",
        label: "Anlægsart",
        show: false
      },
      {
        id: "aktivStatus",
        label: "Aktiv status",
        show: false
      },
      {
        id: "sagsbehandler",
        label: "Sagsbehandler",
        show: true
      },
      {
        id: "titel",
        label: "Titel",
        show: false
      },
      {
        id: "tilsynstype",
        label: "Tilsynstype",
        show: true
      },
      {
        id: "antal",
        label: "Antal",
        show: true
      },
      {
        id: "jupiterId",
        label: "JupiterId",
        show: false
      },
      {
        id: "oeverigeVirksomhedstyper",
        label: "Øvrige virksomhedstyper",
        show: false,
        sort: false
      }
    ];
  }
  get filters(): IMwTableFilter[] {
    return [
      new JupiterAktivStatusFilter("aktivStatusId", "Aktiv status", "Aktiv status"),
      new DateRangeFilter(nameof<VandTilsynReportModel>("planlagtDato"), "Planlagt dato", "Planlagt dato"),
      new JupiteranlaegsartFilter("anlaegsArtId", "Anlægsart", "Anlægsart"),
      new VandanlaegVirksomhedsTypeFilter("primaerVirksomhedstypeId", "Primær virksomhedstype", "Primær virksomhedstype"),
      new VandanlaegTilsynTypeFilter("tilsynstypeId", "Tilsynstype", "Tilsynstype"),
      new VandanlaegVirksomhedsTypeFilter("oeverigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper")
    ];
  }
  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get reportType(): ReportType {
    return ReportType.VandTilsyn;
  }
}
