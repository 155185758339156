import Bruger, { Kunde } from "@/models/bruger";
import { decodeJwt } from "jose";

export async function decodeToken(token: string) {
  const result: unknown = decodeJwt(token);
  return new Principal(result as Principal);
}

export class Principal {
  constructor(init?: Partial<Principal>) {
    if (init) {
      Object.assign(this, init);
    }
  }

  private username: string = "";
  private userId: string = "";
  private customerId: string = "";
  private customerName: string = "";
  private municipalityNumber: number = 0;
  private roles: string = "";
  private modules: string = "";

  get bruger() {
    const user = new Bruger();
    user.userName = this.username;
    user.brugerId = this.userId;
    user.roller = JSON.parse(this.roles) as string[];
    user.moduler = JSON.parse(this.modules) as string[];
    return user;
  }

  get customer() {
    const municipality = new Kunde();
    municipality.kundeId = this.customerId;
    municipality.kundeNavn = this.customerName;
    municipality.kundeNr = this.municipalityNumber.toString();
    return municipality;
  }
}
