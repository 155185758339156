<template>
  <button :class="['btn', `btn-${color}`, computedSize]" :type="type" @click="onClick" data-cy="button">
    <template v-if="loading">
      <MwSpinner />
      {{ loadingText }}
    </template>
    <template v-else>
      <MwIcon :icon="icon" :type="iconType" v-if="icon" :class="{ 'mr-0': !$slots.default }" />
      <slot></slot>
    </template>
  </button>
</template>
<script lang="ts" setup>
import MwSpinner from "@/components/mwUtils/MwSpinner.vue";
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import { computed, defineProps, withDefaults } from "vue";

interface Props {
  icon?: string;
  iconType?: "fa" | "far" | "fas" | "fal" | "fad" | "regular" | "solid" | "light" | "duotone" | "pro";
  loading?: boolean;
  loadingText?: string;
  type?: "button" | "submit" | "reset";
  color?: "primary" | "secondary" | "info";
  size?: "x-small" | "small" | "medium" | "large";
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  type: "button",
  color: "primary",
  size: "medium"
});

const emit = defineEmits<{
  (eventName: "click", event: Event): void;
}>();

function onClick(event: Event) {
  emit("click", event);
}

const computedSize = computed(() => {
  switch (props.size) {
    case "x-small":
      return "btn-xs";
    case "small":
      return "btn-sm";
    case "medium":
      return "";
    case "large":
      return "btn-lg";
  }

  return "";
});
</script>
<style scoped></style>
