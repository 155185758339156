import moment from "moment";
import Brugerbetaling from "./brugerbetaling";
import { BetalingsType } from "../betalingstype";
import { Lookup } from "@/models/lookup";

export class Basis {
  bemaerkning: string = "";
  chrNr: string = "";
  cvrNr: string = "";
  virksomhedsGruppeId: string = "";
  hovedaktivitet: string | null = "";
  kategori: string = "";
  miljoemyndighedId: string = "";
  dmaMyndighedstyperId: string = "";
  virksomhedstype: string = "";
  navn: string = "";
  pNr: string = "";
  omfattetAfAktivtTilsyn: boolean = false;
  branche: string = "";
  bibrancher: string = "";

  /**
   * This is an aggregate of the data related to "brugerbetaling".
   */
  brugerbetaling: Brugerbetaling | null = null;

  anvendCVRAdresseVedIndberetning: boolean = false;
  slettet: boolean = false;
  omfattetAfVOC: boolean = false;
  risikovirksomhed: boolean = false;
  ophoertDato: moment.Moment | null = null;
  omfattetAfAktivtTilsynDato: moment.Moment | null = null;
  etableringsDato: moment.Moment | null = null;
  primaerSagsbehandler: Lookup | null = null;
  sagsbehandlerListe: Lookup[] = [];
  biaktiviteter: string[] = [];
  aktivitetsListe: any[] = [];

  //DMA
  dmaStatusId: string = "";
  dmaStatusDato: moment.Moment | null = null;
  dmaStaedfaestVedCVR: boolean = false;
  dmaUrlForVirksomhed: string = "";
  dmabatKonklusionId: string[] = [];
  dmaSenestOpdateringDato: moment.Moment | null = null;

  //Betaler
  omfattetAfBrugerbetaling: boolean = false;
  betalerTypeKode: BetalingsType | null = null;
  betalingsReference: string | null = null; //For "betalerTypeKode" == EAN
  kontaktpersonId: string | null = null; //For "betalerTypeKode" == Kontaktperson
  betalerBemaerkning: string | null = null;
}
