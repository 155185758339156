export class Lookup {
  [key: string]: any;
  id: string | boolean | null = null;
  brugerId?: string | null = null;
  kode: string = "";
  navn: string = "-";
  beskrivelse: string = "-";
  prioritet?: number = 0;
  dmaKode?: boolean | null = null;
  aktiv?: boolean | undefined = undefined;
}

export class AfgorelseLookup {
  id: string = "00000000-0000-0000-0000-000000000000";
  brugerId?: string | null = null;
  kode: string = "";
  navn: string = "-";
  beskrivelse: string = "-";
  prioritet?: number = 0;
  dmaKode?: boolean | null = null;
}

export class TidsregistreringAktivitetTypeLookup {
  id: string = "";
  kode: string = "";
  navn: string = "";
  tilladLoebendeAfregning: boolean = false;
}
