<template>
  <button
    :title="notification.beskrivelse ?? ''"
    type="button"
    class="dropdown-item notification-item"
    :class="{ 'is-disabled': !notificationType.pathToAction }"
    @click="onClick"
  >
    <div class="notification-content">
      <div class="notification-header">
        <MwIcon :icon="notificationType.badge ?? ''" class="notification-icon" />
        <small>{{ formattedDate }}</small>
      </div>
      <div class="notification-body">
        {{ notificationType.title }}:
        <small>{{ notification.beskrivelse }}</small>
      </div>
    </div>
  </button>
  <div class="dropdown-divider"></div>
</template>
<script setup lang="ts">
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import { Notification } from "@/models/bruger";
import { defineProps, computed } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { NotifikationTypeEnum } from "@/models/notifikationTypeEnum";

const router = useRouter();

const props = defineProps({
  notification: {
    type: Object as () => Notification,
    required: true
  }
});

const formattedDate = computed(() => {
  if (props.notification.oprettetDatoTid) {
    return moment(props.notification.oprettetDatoTid).format("DD-MM-YYYY HH:mm:ss");
  }
  return "";
});

const notificationType = computed(() => {
  let title;
  let pathToAction;
  let badge;
  switch (props.notification.notifikationType) {
    case NotifikationTypeEnum.DMAStatusInfo:
      title = "DMA dataoverførselsfejl";
      pathToAction = "/frister/dmastatus";
      badge = "file-alt";
      break;
    case NotifikationTypeEnum.JupiterStatusInfo:
      title = "Jupiter dataoverførselsfejl";
      pathToAction = "/frister/jupiterstatus";
      badge = "tint";
      break;
    case NotifikationTypeEnum.JupiterCertificateRenewalInfo:
      title = "Jupiter certifikat - udløber snart";
      pathToAction = "/admin/jupiterCertifikat";
      badge = "file-certificate";
      break;
    default:
      title = "Ukendt notifikation";
      break;
  }
  return { title, pathToAction, badge };
});

function onClick() {
  if (notificationType.value.pathToAction) {
    router.push(notificationType.value.pathToAction);
  }
}
</script>

<style scoped>
.notification-item {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.5rem;
  height: 4rem;
  width: 300px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: normal;
}
.notification-content {
  width: 100%;
}

.notification-header {
  display: flex;
  align-items: center;
}

.notification-icon {
  padding-right: 0.25rem;
}

.notification-body {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.notification-item.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.dropdown-divider {
  border-top: 1px solid #dfdfdf;
}
</style>
