import { computed } from "vue";

export function useValidations(validations: any) {
  const errors = computed(() => {
    const errors: string[] = [];

    if (validations) {
      validations.$silentErrors.forEach((error: any) => errors.push(error.$message));
      validations.$errors.forEach((error: any) => errors.push(error.$message));
    }

    return errors;
  });

  const hasErrors = computed(() => {
    return errors.value.length > 0;
  });

  const lastError = computed(() => {
    return errors.value.at(-1);
  });

  const isRequired = computed(() => {
    return !!validations?.required;
  });

  const currentMax = computed(() => {
    return validations?.maxLength?.$params.max;
  });

  return { errors, hasErrors, lastError, isRequired, currentMax };
}
