import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { fetchGet } from "@/utils/networking/httpclient";

export default class VandanlaegVirksomhedsTypeFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string, modelAttribute: string = "id") {
    super(field, title, shortLabel, "contains", null);
    //sometimes primaerVirksomhedstype is saved and passed as an id, sometimes as the specific string
    //here we are able to specify what value should be passed to the backend, based on which attribute is being looked at
    this.modelAttribute = modelAttribute;

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Virksomhedstyper",
        labelAttribute: "beskrivelse"
      },
      async mounted() {
        const { data } = await fetchGet("MWVandanlaegLookUp/list/jupitervirksomhedstype");
        this.selectProps.options = data;
      }
    };
  }
}
