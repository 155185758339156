import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";

export default class VandTilladelseAktivFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "equals", null);

    this.editorProps = {
      selectProps: {
        label: "Gældende tilladelse",
        labelAttribute: "navn",
        autoSort: false,
        options: [
          { navn: "Gældende", id: true, kode: "1", beskrivelse: "Tilladelse er gældende" },
          {
            navn: "Udløbet",
            id: false,
            kode: "2",
            beskrivelse: "Tilladelse er udløbet"
          }
        ]
      }
    };
  }

  createUrlString(): string {
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${this.model.value})`;
  }

  createDisplayValue() {
    return `${this.shortLabel}: ${this.model.value ? "Gældende" : "Udløbet"}`;
  }
}
