import { fetchGet } from "@/utils/networking/httpclient";
import SelectFilter from "../SelectFilter";

export default class JupiteranlaegsartFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string, modelAttribute: string = "id") {
    super(field, title, shortLabel, "contains", null);
    this.modelAttribute = modelAttribute;

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Anlaegsart",
        labelAttribute: "navn"
      },
      async mounted() {
        const { data } = await fetchGet("MWVandanlaegLookUp/list/jupiteranlaegsart");
        this.selectProps.options = data;
      }
    };
  }
}
