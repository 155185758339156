<template>
  <SdpPopover activation="click" :header="header">
    <MwButton color="secondary" size="small" title="" :disabled="disabled" :icon="icon" class="custom-icon-size" />
    <template #popper-content>
      <span class="d-block" v-for="line in formattedContent">
        {{ line }}
        <br v-if="line === ''" />
      </span>
    </template>
  </SdpPopover>
</template>

<script lang="ts" setup>
import MwButton from "@/components/mwUtils/MwButton.vue";

import { computed, defineProps, withDefaults } from "vue";

export interface Props {
  icon?: string;
  header?: string;
  content: string | string[];
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: "question-circle",
  header: "",
  disabled: false
});

const formattedContent = computed<string[]>(() => {
  if (props.content) {
    if (typeof props.content === "string") {
      return [props.content];
    } else {
      return props.content;
    }
  }
  return [];
});
</script>
<style scoped>
.custom-icon-size {
  font-size: inherit;
}
</style>
