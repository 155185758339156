import Bruger from "@/models/bruger";
import Virksomhed from "@/models/virksomhed/virksomhed";
import { KundeRoleEnums } from "@/models/kundeRoleEnums";
import { createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw, RouterView } from "vue-router";
import { useSessionStore } from "@/stores/session";
import { useFakturaStore } from "@/stores/faktura";
import { useVirksomhedStore } from "@/stores/virksomhed";
import { useVandanlaegStore } from "@/stores/vandanlaeg";
import { usePlanlaegningStore } from "@/stores/planlaegning";
import { TilsynReportModel } from "@/models/rapport/tilsynReportModel";
import { IGenericReportProps } from "@/components/rapport/GenericRapport.vue";
import { STSErrorProps } from "../views/STSErrorView.vue";
import { nameof } from "@/utils/utils";
import { VandTilsynReportModel } from "@/models/rapport/vandTilsynReportModel";
import { VandHaandhaevelseReportModel } from "@/models/rapport/VandHaandhaevelseReportModel";
import { VandanlaegKontaktpersonerReportModel } from "@/models/rapport/vandanlaegKontaktpersonerReportModel";
import { VandTilladelseReportModel } from "@/models/rapport/vandTilladelseReportModel";
import { VandTilladelseBoringReportModel } from "@/models/rapport/vandTilladelseBoringReportModel";
import { VandOppumpetReportModel } from "@/models/rapport/VandOppumpetReportModel";
import { VandForsyningsfordelingReportModel } from "@/models/rapport/VandForsyningsfordelingReportModel";
import { BoringTilladelseReportModel } from "@/models/rapport/boringTilladelseReportModel";
import { VandBoringPejlingReportModel } from "@/models/rapport/VandBoringPejlingReportModel";

const Home = () => import("../views/HomeView.vue");
const Login = () => import("../views/LoginView.vue");
const STSError = () => import("../views/STSErrorView.vue");
const LoggedOut = () => import("../views/LoggedOutView.vue");

const NotFound = () => import("../views/NotFoundView.vue");
const Virksomheder = () => import("../views/VirksomhederView.vue");
const VirksomhedDetails = () => import("../views/VirksomhedDetailsView.vue");
const Landbrug = () => import("../views/LandbrugView.vue");

const VandanlaegListe = () => import("../views/VandanlaegView.vue");
const VandanlaegDetails = () => import("../views/VandanlaegDetailsView.vue");
const VandanlaegStamOplysninger = () => import("../components/vandanlaegDetails/tabs/stamoplysninger/StamoplysningerTab.vue");
const Maengder = () => import("../components/vandanlaegDetails/tabs/maengder/MaengderTab.vue");
const FasteProevetagningsteder = () =>
  import("../components/vandanlaegDetails/tabs/fasteproevetagningsteder/FasteProevetagningstederTab.vue");
const VandanlaegTilsyn = () => import("../components/vandanlaegDetails/tabs/tilsyn/TilsynTab.vue");
const Diadem = () => import("../components/vandanlaegDetails/tabs/diadem/DiademTab.vue");
const VandanlaegHaandhaevelser = () => import("../components/vandanlaegDetails/tabs/haandhaevelser/HaandhaevelserTab.vue");
const Tilladelser = () => import("../components/vandanlaegDetails/tabs/tilladelser/TilladelserTab.vue");
const Boringer = () => import("../components/vandanlaegDetails/tabs/boringer/BoringerTab.vue");

const StamOplysninger = () => import("../components/virksomhedDetails/tabs/stamoplysninger/StamoplysningerTab.vue");
const Haandhaevelser = () => import("../components/virksomhedDetails/tabs/haandhaevelser/HaandhaevelserTab.vue");
const Afgoerelser = () => import("../components/virksomhedDetails/tabs/afgoerelser/AfgoerelserTab.vue");
const Risikovurdering = () => import("../components/virksomhedDetails/tabs/risikovurdering/RisikovurderingTab.vue");
const Tilsyn = () => import("../components/virksomhedDetails/tabs/tilsyn/TilsynTab.vue");
const Brugerbetaling = () => import("../components/virksomhedDetails/tabs/brugerbetaling/BrugerbetalingTab.vue");

const PlanlaegningsPerioder = () => import("../views/PlanlaegningsPerioderView.vue");
const AarsPlanlaegning = () => import("../views/AarsPlanlaegningView.vue");
const PlanlaegningOversigt = () => import("../components/planlaegning/tabs/TilsynsplanTab.vue");
const PlanlaegningRessourcer = () => import("../components/planlaegning/tabs/RessourcerTab.vue");
const PlanlaegningKategori1a1b = () => import("../components/planlaegning/tabs/Kategori1a1bTab.vue");
const PlanlaegningKategori2 = () => import("../components/planlaegning/tabs/Kategori2Tab.vue");
const OpfoelgningOekonomi = () => import("../components/planlaegning/tabs/OpfoelgningOekonomiTab.vue");

const Fyringsanlaeg = () => import("../components/virksomhedDetails/tabs/fyringsanlaeg/FyringsanlaegTab.vue");
const Admin = () => import("../views/AdminView.vue");
const PersonligPlanlaegning = () => import("../views/FristerView.vue");
// const Planer = () => import("../views/Planer.vue");
const FraseBibliotek = () => import("../components/admin/tabs/FraseBibliotekTab.vue");
const AdministrativeOplysninger = () => import("../components/admin/tabs/AdministrativeOplysningerTab.vue");
const Skabeloner = () => import("../components/admin/tabs/SkabelonerTab.vue");
const BrugerAdministration = () => import("../components/admin/tabs/BrugerAdministrationTab.vue");
// const ManageSystemMessage = () => import("../components/admin/tabs/ManageSystemMessage.vue");
const DmaStatus = () => import("../components/frister/tabs/Status/DmaStatusTab.vue");
const JupiterStatus = () => import("../components/frister/tabs/Status/JupiterStatusTab.vue");
const MinimapKoblinger = () => import("../components/admin/tabs/MinimapKoblingerTab.vue");
const Certificate = () => import("../components/admin/tabs/CertificateTab.vue");
const Opbevaringslagre = () => import("../components/virksomhedDetails/tabs/opbevaringslagre/OpbevaringslagreTab.vue");
const Dyrehold = () => import("../components/landbrugDetails/tabs/dyrehold/DyreholdTab.vue");

const Validering = () => import("../views/ValideringView.vue");
const Analyseproever = () => import("../components/validering/tabs/AnalyseproeverTab.vue");
const OppumpedeMaengderAnlaeg = () => import("../components/validering/tabs/OppumpedeMaengderAnlaegTab.vue");
const OppumpedeMaengderBoringIndtag = () => import("../components/validering/tabs/OppumpedeMaengderBoringIndtagTab.vue");
const Indberetninger = () => import("../components/validering/tabs/IndberetningerTab.vue");
const Pejlinger = () => import("../components/validering/tabs/PejlingerTab.vue");
const Kontaktpersoner = () => import("../components/vandanlaegDetails/tabs/stamoplysninger/Kontaktpersoner.vue");

const Fakturering = () => import("../views/FaktureringView.vue");
const FakturaforslagList = () => import("../components/fakturering/tabs/FakturaforslagTab.vue");
const GodkendteFakturaforslag = () => import("../components/fakturering/tabs/GodkendteFakturaforslagTab.vue");
const FakturaOversigt = () => import("../components/fakturering/tabs/FakturaOversigtTab.vue");
const FakturaFejledeOversigt = () => import("../components/fakturering/tabs/FakturaFejledeOversigtTab.vue");

const GenericRapport = () => import("@/components/rapport/GenericRapport.vue");
const Rapport = () => import("../components/rapport/RapportTab.vue");
const Opfoelgning = () => import("../components/frister/tabs/Opfoelgning/OpfoelgningTab.vue");

const DefaultLayout = () => import("@/layouts/DefaultLayout.vue");

const NoAccess = () => import("../views/NoAccessView.vue");

const SystemAdmin = () => import("../views/SystemAdminView.vue");
const KundeModuler = () => import("../components/systemAdmin/tabs/KundeModulerTab.vue");
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { breadcrumbName: "Hjem", breadcrumbUrl: "" }
  },
  {
    path: "/swecologin",
    name: "swecologin",
    component: Login
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter() {
      if (import.meta.env.VITE_LOGIN_TYPE === "STS" && !localStorage.usertoken) {
        // We use a timeout to provide the user with a better user experience, so they can read the redirect text
        setTimeout(() => {
          window.location.href = import.meta.env.VITE_STS_URL;
        }, 3000);
      }
      return true;
    }
  },
  {
    path: "/stslogin",
    name: "stslogin",
    component: RouterView, //This fixes error with entry not conforming with interface. Renders blank routerview, but should never need to be rendered
    async beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      if (to.query && to.query.userid) {
        const sessionStore = useSessionStore();
        const response = await sessionStore.loginSts({ stsId: to.query.userid });
        if (response.success) {
          return {
            name: "home"
          };
        } else {
          console.error("Could not log in with userid: " + to.query.userid);
          console.error("Server response:", response.error);
          //In lack of a better transport method, we set the message in local storage.
          //Tried passing it using query params, but seems odd passing text that way depending on the length.
          if (response.error) {
            try {
              localStorage.setItem(nameof<STSErrorProps>("stsErrorMessage"), response.error);
            } catch (ex) {
              //Do nothing
            }
          }
          return {
            name: "stserror"
          };
        }
      } else {
        console.error("userid param not found in query");
        return {
          name: "stserror"
        };
      }
    }
  },
  {
    path: "/stserror",
    name: "stserror",
    component: STSError
  },
  {
    path: "/logout",
    name: "logout",
    component: LoggedOut
  },
  {
    path: "/systemadmin",
    name: "systemadmin",
    component: SystemAdmin,
    meta: {
      requiredResource: KundeRoleEnums.SYSTEM_ADMIN,
      breadcrumbName: "Systemadministration",
      breadcrumbUrl: "systemadmin"
    },
    children: [
      {
        name: "kundemoduler",
        path: "/kundemoduler",
        component: KundeModuler,
        meta: { breadcrumbName: "kundemoduler", breadcrumbUrl: "systemadmin/kundemoduler", displayName: "Kundemoduler" }
      }
    ]
  },
  {
    path: "/virksomheder",
    name: "virksomheder",
    component: Virksomheder,
    meta: {
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED_LAES,
      breadcrumbName: "Virksomheder",
      breadcrumbUrl: "virksomheder"
    }
  },
  {
    path: "/virksomheder/:id",
    component: VirksomhedDetails,
    meta: {
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED_LAES,
      breadcrumbName: "Virksomheder",
      breadcrumbUrl: "virksomheder",
      area: "virksomhed"
    },
    /*
     * Fetch the "virksomhed" before the component or one of it's child components is created.
     */
    async beforeEnter(to: RouteLocationNormalized) {
      const virksomhedStore = useVirksomhedStore();
      await virksomhedStore.fetchVirksomhed(to.params.id as string);
    },
    children: [
      {
        name: "virksomhed",
        path: "",
        // component: StamOplysninger,
        redirect: (to: RouteLocationNormalized) => {
          return { name: "virksomhed-stamoplysninger", params: { id: to.params.id } };
        },
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "virksomheder/:virksomhedid/stamoplysninger" }
      },
      {
        path: "stamoplysninger",
        component: StamOplysninger,
        name: "virksomhed-stamoplysninger",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "virksomhed/:virksomhedid/stamoplysninger",
          displayName: "Stamoplysninger"
        }
      },
      {
        path: "tilsyn/:tilsynid?",
        component: Tilsyn,
        name: "virksomhed-tilsyn",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "virksomhed/:virksomhedid/tilsyn", displayName: "Tilsyn" }
      },
      {
        path: "haandhaevelser/:haandhaevelseid?",
        component: Haandhaevelser,
        name: "virksomhed-haandhaevelser",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "virksomhed/:virksomhedid/haandhaevelser",
          displayName: "Håndhævelser"
        }
      },
      {
        path: "afgoerelser/:afgoerelseid?",
        component: Afgoerelser,
        name: "virksomhed-afgoerelser",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "virksomhed/:virksomhedid/afgoerelser", displayName: "Afgørelser" }
      },
      {
        path: "risikovurdering/:risikovurderingid?",
        component: Risikovurdering,
        name: "virksomhed-risikovurdering",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "virksomhed/:virksomhedid/risikovurdering",
          displayName: "Risikovurdering"
        }
      },
      {
        path: "fyringsanlaeg/:fyringsanlaegid?",
        component: Fyringsanlaeg,
        name: "virksomhed-fyringsanlaeg",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "/:virksomhedid/fyringsanlaeg", displayName: "Fyringsanlæg" }
      },
      {
        path: "brugerbetaling/:brugerbetalingid?",
        component: Brugerbetaling,
        name: "virksomhed-brugerbetaling",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "virksomhed/:virksomhedid/brugerbetaling",
          displayName: "Brugerbetaling",
          // This callback determines if the tab corresponding to this route is active in the "VirksomhedDetails" view.
          isTabEnabled: (company: Virksomhed) => {
            return company.basis.omfattetAfBrugerbetaling;
          }
        }
      },
      {
        path: "opbevaringslagre/:opbevaringslagerid?",
        component: Opbevaringslagre,
        name: "virksomhed-opbevaringslagre",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "landbrug/:virksomhedid/opbevaringslagre",
          displayName: "Opbevaringslagre"
        }
      }
    ]
  },
  {
    path: "/landbrug",
    name: "landbrugListe",
    component: Landbrug,
    meta: {
      breadcrumbName: "Landbrug",
      breadcrumbUrl: "landbrug",
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_LANDBRUG_LAES
    }
  },
  {
    path: "/landbrug/:id",
    component: VirksomhedDetails,
    meta: {
      breadcrumbName: "Landbrug",
      breadcrumbUrl: "landbrug",
      area: "landbrug",
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_LANDBRUG_LAES
    },
    /*
     * Fetch the "virksomhed" before the component or one of it's child components is created.
     */
    async beforeEnter(to: RouteLocationNormalized) {
      const virksomhedStore = useVirksomhedStore();
      await virksomhedStore.fetchVirksomhed(to.params.id as string);
    },
    children: [
      {
        name: "landbrug",
        path: "",
        // redirect: "/landbrug/stamoplysninger",
        // component: StamOplysninger,
        redirect: (to: RouteLocationNormalized) => {
          return {
            name: "landbrug-stamoplysninger",
            params: { id: to.params.id }
          };
        },
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/stamoplysninger" }
      },
      {
        path: "stamoplysninger",
        component: StamOplysninger,
        name: "landbrug-stamoplysninger",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "landbrug/:virksomhedid/stamoplysninger",
          displayName: "Stamoplysninger"
        }
      },
      {
        path: "tilsyn/:tilsynid?",
        component: Tilsyn,
        name: "landbrug-tilsyn",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/tilsyn", displayName: "Tilsyn" }
      },
      {
        path: "haandhaevelser/:haandhaevelseid?",
        component: Haandhaevelser,
        name: "landbrug-haandhaevelser",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/haandhaevelser", displayName: "Håndhævelser" }
      },
      {
        path: "afgoerelser/:afgoerelseid?",
        component: Afgoerelser,
        name: "landbrug-afgoerelser",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/afgoerelser", displayName: "Afgørelser" }
      },
      {
        path: "risikovurdering/:risikovurderingid?",
        component: Risikovurdering,
        name: "landbrug-risikovurdering",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "landbrug/:virksomhedid/risikovurdering",
          displayName: "Risikovurdering"
        }
      },
      {
        path: "fyringsanlaeg/:fyringsanlaegid?",
        component: Fyringsanlaeg,
        name: "landbrug-fyringsanlaeg",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/fyringsanlaeg", displayName: "Fyringsanlæg" }
      },
      {
        path: "brugerbetaling/:brugerbetalingid?",
        component: Brugerbetaling,
        name: "landbrug-brugerbetaling",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "landbrug/:virksomhedid/brugerbetaling",
          displayName: "Brugerbetaling",
          // This callback determines if the tab corresponding to this route is active in the "VirksomhedDetails" view.
          isTabEnabled: (company: Virksomhed) => {
            return company.basis.omfattetAfBrugerbetaling;
          }
        }
      },
      {
        path: "dyrehold/:dyreholdid?",
        component: Dyrehold,
        name: "landbrug-dyrehold",
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "landbrug/:virksomhedid/dyrehold", displayName: "Dyrehold" }
      },
      {
        path: "opbevaringslagre/:opbevaringslagerid?",
        component: Opbevaringslagre,
        name: "landbrug-opbevaringslagre",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "landbrug/:virksomhedid/opbevaringslagre",
          displayName: "Opbevaringslagre"
        }
      }
    ]
  },
  {
    path: "/vandanlaeg",
    name: "vandanlaegListe",
    component: VandanlaegListe,
    meta: {
      breadcrumbName: "Vandanlæg",
      breadcrumbUrl: "vandanlaeg",
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG_LAES
    }
  },
  {
    path: "/vandanlaeg/:id",
    component: VandanlaegDetails,
    meta: {
      breadcrumbName: "Vandanlæg",
      breadcrumbUrl: "vandanlaeg",
      area: "vandanlaeg",
      requiredResource: KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG_LAES
    },
    /*
     * Fetch the vandanlæg before the component or one of it's child components is created.
     */
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const vandanlaegStore = useVandanlaegStore();
      await vandanlaegStore.fetchVandanlaeg(to.params.id);
    },
    children: [
      {
        name: "vandanlaeg",
        path: "",
        // component: VandanlaegStamOplysninger,
        redirect: (to: RouteLocationNormalized) => {
          return { name: "vandanlaeg-stamoplysninger", params: { id: to.params.id } };
        },
        meta: { breadcrumbName: ":virksomhednavn", breadcrumbUrl: "vandanlaeg/:virksomhedid/stamoplysninger" }
      },
      {
        path: "stamoplysninger/:stamoplysningerid?",
        component: VandanlaegStamOplysninger,
        name: "vandanlaeg-stamoplysninger",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/stamoplysninger",
          displayName: "Stamoplysninger"
        }
      },
      {
        path: "indtag/:boringid?",
        component: Boringer,
        name: "vandanlaeg-indtag",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/indtag",
          displayName: "Boring / indtag"
        }
      },
      {
        path: "tilladelser/:tilladelseid?",
        component: Tilladelser,
        name: "vandanlaeg-tilladelser",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/tilladelser",
          displayName: "Tilladelser"
        }
      },
      {
        path: "tilsyn/:tilsynid?",
        component: VandanlaegTilsyn,
        name: "vandanlaeg-tilsyn",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/tilsyn",
          displayName: "Tilsyn / opfølgning"
        }
      },
      {
        path: "haandhaevelser/:haandhaevelseid?",
        component: VandanlaegHaandhaevelser,
        name: "vandanlaeg-haandhaevelser",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/haandhaevelser",
          displayName: "Håndhævelser"
        }
      },
      {
        path: "diadem",
        component: Diadem,
        name: "vandanlaeg-diadem",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/diadem",
          displayName: "Diadem"
        }
      },
      {
        path: "maengder",
        component: Maengder,
        name: "vandanlaeg-maengder",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/maengder",
          displayName: "Mængder"
        }
      },
      {
        path: "fasteproevetagningssteder",
        component: FasteProevetagningsteder,
        name: "vandanlaeg-fasteproevetagningsteder",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/fasteproevetagningssteder",
          displayName: "Faste Prøvetagningsteder"
        }
      },
      {
        path: "vandproever",
        component: Analyseproever,
        name: "vandanlaeg-vandproever",
        meta: {
          breadcrumbName: ":virksomhednavn",
          breadcrumbUrl: "vandanlaeg/:virksomhedid/vandproever",
          displayName: "Prøver"
        }
      }
    ]
  },
  {
    path: "/planlaegningsperioder",
    name: "planlaegningsperioderListe",
    component: PlanlaegningsPerioder,
    meta: {
      breadcrumbName: "Planlægningsperioder",
      breadcrumbUrl: "planlaegningsperioder",
      requiredResource:
        KundeRoleEnums.SAGSBEHANDLER_AARSPLAN +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_LANDBRUG +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED
    }
  },
  {
    path: "/planlaegningsperioder/:id",
    component: AarsPlanlaegning,
    meta: {
      breadcrumbName: "Planlægningsperioder",
      breadcrumbUrl: "planlaegningsperioder",
      area: "planlaegningsperioder",
      requiredResource:
        KundeRoleEnums.SAGSBEHANDLER_AARSPLAN +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_LANDBRUG +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED
    },

    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      const planlaegningStore = usePlanlaegningStore();
      planlaegningStore.currentPlanlaegningsPeriodeId = to.params.id;
    },
    children: [
      {
        name: "aarsplanlaegning",
        path: "",
        // component: AarsPlanlaegning,
        redirect: (to: RouteLocationNormalized) => {
          return { name: "aarsplanlaegning-oversigt", params: { id: to.params.id } };
        },
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/oversigt"
        }
      },
      {
        path: "oversigt/:aarsplanlaegningid?",
        component: PlanlaegningOversigt,
        name: "aarsplanlaegning-oversigt",
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/oversigt",
          displayName: "Tilsynsplan"
        }
      },
      {
        path: "ressourcer/:aarsplanlaegningid?",
        component: PlanlaegningRessourcer,
        name: "aarsplanlaegning-ressourcer",
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/ressourcer",
          displayName: "Ressourcer"
        }
      },
      {
        path: "kategori1a1b/:aarsplanlaegningid?",
        component: PlanlaegningKategori1a1b,
        name: "aarsplanlaegning-kategori1a1b",
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/kategori1a1b",
          displayName: "Kategori 1a + 1b"
        }
      },
      {
        path: "kategori2/:aarsplanlaegningid?",
        component: PlanlaegningKategori2,
        name: "aarsplanlaegning-kategori2",
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/kategori2",
          displayName: "Kategori 2"
        }
      },
      {
        path: "opfoelgningoekonomi/:aarsplanlaegningid?",
        component: OpfoelgningOekonomi,
        name: "planlaegningsperioder-opfoelgningoekonomi",
        meta: {
          breadcrumbName: ":planlaegningsAar",
          breadcrumbUrl: "planlaegningsperioder/:aarsplanlaegningid/opfoelgningoekonomi",
          displayName: "Opfølgning af økonomi"
        }
      }
    ]
  },

  {
    path: "/admin",
    name: "admin",
    component: Admin,
    redirect: "/admin/frasebibliotek",
    meta: {
      breadcrumbName: "Administration",
      breadcrumbUrl: "admin",
      requiredResource: KundeRoleEnums.KOMMUNE_ADMIN + "||" + KundeRoleEnums.SAGSBEHANDLER_ADMIN
    },
    children: [
      {
        path: "frasebibliotek/:fraseid?",
        name: "frasebibliotek",
        component: FraseBibliotek,
        meta: { breadcrumbName: "Frase bibliotek", breadcrumbUrl: "admin/frasebibliotek", displayName: "Frase bibliotek" }
      },
      {
        path: "minimapkoblinger/:minimapkoblingid?",
        name: "minimapkoblinger",
        component: MinimapKoblinger,
        meta: {
          breadcrumbName: "minimapkoblinger",
          breadcrumbUrl: "admin/minimapkoblinger",
          displayName: "Minimap koblinger",

          isTabVisible() {
            const sessionStore = useSessionStore();
            return sessionStore.hasRights(KundeRoleEnums.KOMMUNE_ADMIN);
          }
        }
      },
      {
        path: "administrativeoplysninger/:administrativeoplysningerid?",
        name: "administrativeoplysninger",
        component: AdministrativeOplysninger,
        meta: {
          breadcrumbName: "administrativeoplysninger",
          breadcrumbUrl: "admin/administrativeoplysninger",
          displayName: "Administrative oplysninger"
        }
      },
      {
        path: "dokumentskabeloner",
        name: "dokumentskabeloner",
        component: Skabeloner,
        meta: {
          breadcrumbName: "dokumentskabeloner",
          breadcrumbUrl: "admin/dokumentskabeloner",
          displayName: "Skabeloner"
        }
      },
      // {
      //   path: "systemmessages",
      //   name: "systemmessages",
      //   component: ManageSystemMessage,
      //   meta: {
      //     requiredResource: KundeRoleEnums.SYSTEM_ADMIN,
      //     breadcrumbName: "systemmessages",
      //     breadcrumbUrl: "admin/systemmessages",
      //     displayName: "Systembeskeder"
      //   }
      // },
      {
        path: "BrugerAdministration",
        name: "BrugerAdministration",
        component: BrugerAdministration,
        meta: {
          requiredResource: KundeRoleEnums.KOMMUNE_ADMIN,
          breadcrumbName: "Brugeradministration",
          breadcrumbUrl: "admin/brugeradministration",
          displayName: "Brugeradministration"
        }
      },
      {
        path: "JupiterCertifikat",
        name: "Jupiter Certifikat",
        component: Certificate,
        meta: {
          requiredResource: KundeRoleEnums.KOMMUNE_ADMIN,
          breadcrumbName: "Jupitercertifikat",
          breadcrumbUrl: "admin/jupitercertifikat",
          displayName: "Jupiter Certifikat"
        }
      }
    ]
  },
  {
    path: "/validering",
    name: "validering",
    component: Validering,
    redirect: "/validering/vandproever",
    meta: { breadcrumbName: "Validering", breadcrumbUrl: "validering" },
    children: [
      //     {
      //       path: "",
      //       component: Vandproever,
      //       redirect: "/validering/vandproever",
      //       meta: {
      //         breadcrumbName: "Analyseprøver i jupiter",
      //         breadcrumbUrl: "validering/proever",
      //         displayName: "Analyseprøver i jupiter"
      //       }
      //     },
      {
        path: "vandproever",
        name: "vandproever",
        component: Analyseproever,
        meta: {
          breadcrumbName: "Analyseprøver i jupiter",
          breadcrumbUrl: "validering/proever",
          displayName: "Analyseprøver i jupiter"
        }
      },
      {
        path: "emailvandanlaeg",
        name: "emailvandanlaeg",
        component: Indberetninger,
        meta: {
          breadcrumbName: "Oversigt over indberetninger",
          breadcrumbUrl: "validering/emailvandanlaeg",
          displayName: "Oversigt over indberetninger"
        }
      },
      {
        path: "oppumpedemaengeranlaeg",
        name: "oppumpedemaengeranlaeg",
        component: OppumpedeMaengderAnlaeg,
        meta: {
          breadcrumbName: "Oppumpede mænger, Anlæg",
          breadcrumbUrl: "validering/oppumpedemaengeranlaeg",
          displayName: "Oppumpede mænger, Anlæg"
        }
      },
      {
        path: "oppumpedemaengderboringindtag",
        name: "oppumpedemaengderboringindtag",
        component: OppumpedeMaengderBoringIndtag,
        meta: {
          breadcrumbName: "Oppumpede mængder, Boring",
          breadcrumbUrl: "validering/oppumpedemaengderboringindtag",
          displayName: "Oppumpede mængder, Boring"
        }
      },
      {
        path: "pejlinger",
        name: "pejlinger",
        component: Pejlinger,
        meta: {
          breadcrumbName: "Pejlinger",
          breadcrumbUrl: "validering/pejlinger",
          displayName: "Pejlinger"
        }
      },
      {
        path: "kontaktpersoner/:jupiterkontaktpersonid?/:kontaktpersonnavn?",
        name: "kontaktpersoner",
        component: Kontaktpersoner,
        meta: {
          breadcrumbName: "Kontaktpersoner",
          breadcrumbUrl: "validering/kontaktpersoner",
          displayName: "Kontaktpersoner"
        }
      }
    ]
  },
  {
    path: "/fakturering",
    name: "fakturering",
    component: Fakturering,
    redirect: "/fakturering/fakturaforslagliste",
    meta: {
      requiredResource:
        KundeRoleEnums.SAGSBEHANDLER_LANDBRUG + "||" + KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED + "||" + KundeRoleEnums.OKONOMI_ADMIN,
      breadcrumbName: "Fakturering",
      breadcrumbUrl: "fakturering"
    },
    async beforeEnter() {
      const fakturaStore = useFakturaStore();
      await fakturaStore.fetchOir();
    },
    children: [
      {
        path: "fakturaforslagliste",
        name: "fakturering-fakturaforslagliste",
        component: FakturaforslagList,
        meta: { breadcrumbName: "Fakturaforslag", breadcrumbUrl: "fakturering/fakturaforslagliste", displayName: "Fakturaforslag" }
      },
      {
        path: "godkendtefakturaforslag",
        name: "fakturering-godkendtefakturaforslag",
        component: GodkendteFakturaforslag,
        meta: {
          breadcrumbName: "Godkendte Fakturaforslag",
          breadcrumbUrl: "fakturering/godkendtefakturaforslag",
          displayName: "Godkendte Fakturaforslag"
        }
      },
      {
        // path: 'fakutraoversigt',
        path: "fakturaoversigt",
        name: "fakturering-fakturaoversigt",
        component: FakturaOversigt,
        meta: {
          breadcrumbName: "Fakturaoversigt",
          breadcrumbUrl: "fakturering/fakturaoversigt",
          displayName: "Fakturaoversigt"
        }
      },
      {
        path: "fakturafejledeoversigt",
        name: "fakturering-fakturafejledeoversigt",
        component: FakturaFejledeOversigt,
        meta: {
          breadcrumbName: "Fejlede overførsler",
          breadcrumbUrl: "fakturering/fakturafejledeoversigt",
          displayName: "Fejlede overførsler",
          isTabVisible: () => {
            const fakturaStore = useFakturaStore();
            return fakturaStore.oeir;
          }
        }
      }
    ]
  },
  {
    path: "/rapport",
    name: "rapport",
    component: DefaultLayout,
    redirect: { path: "/rapport/tilsyn" },
    meta: {
      breadcrumbName: "Rapport",
      breadcrumbUrl: "rapport",
      requiredResource:
        KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED_LAES +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_LANDBRUG_LAES +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG_LAES
    },
    children: [
      {
        path: "/rapport/tilsyn",
        component: GenericRapport,
        name: "Tilsyn",
        props: {
          reportModel: new TilsynReportModel()
        } as IGenericReportProps<TilsynReportModel>,
        meta: {
          breadcrumbName: "Tilsyn",
          breadcrumbUrl: "Tilsyn"
        }
      },
      {
        path: "/rapport/haandhaevelser",
        component: Rapport,
        name: "Håndhævelser",
        props: {
          reportId: "20210126-0000-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Håndhævelser",
          breadcrumbUrl: "Håndhævelser"
        }
      },
      {
        path: "/rapport/afgoerelser",
        component: Rapport,
        name: "Afgørelser",
        props: {
          reportId: "20220322-0000-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Afgørelser",
          breadcrumbUrl: "Afgørelser"
        }
      },
      {
        path: "/rapport/beholdere",
        component: Rapport,
        name: "Beholdere",
        props: {
          reportId: "20210208-0001-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Beholdere",
          breadcrumbUrl: "Beholdere"
        }
      },
      {
        path: "/rapport/beholderkontrol",
        component: Rapport,
        name: "Beholder kontrol",
        props: {
          reportId: "20210311-0001-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Beholder kontrol",
          breadcrumbUrl: "Beholder kontrol"
        }
      },
      {
        path: "/rapport/olietank",
        component: Rapport,
        name: "Olietank",
        props: {
          reportId: "20220503-0001-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Olietank",
          breadcrumbUrl: "Olietank"
        }
      },
      {
        path: "/rapport/olietanksinspektion",
        component: Rapport,
        name: "Olietanksinspektion",
        props: {
          reportId: "20220429-0001-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Olietanksinspektion",
          breadcrumbUrl: "Olietanksinspektion"
        }
      },
      {
        path: "/rapport/pladser",
        component: Rapport,
        name: "Pladser",
        props: {
          reportId: "20210209-0002-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Pladser",
          breadcrumbUrl: "Pladser"
        }
      },
      {
        path: "/rapport/brugerbetaling",
        component: Rapport,
        name: "Brugerbetaling",
        props: {
          reportId: "20210128-0000-0000-0000-000000000001"
        },
        meta: {
          breadcrumbName: "Brugerbetaling",
          breadcrumbUrl: "Brugerbetaling"
        }
      },
      {
        path: "/rapport/vandanlaegkontaktpersoner",
        component: GenericRapport,
        name: "Vandanlæg kontaktpersoner",
        props: {
          reportModel: new VandanlaegKontaktpersonerReportModel()
        } as IGenericReportProps<VandanlaegKontaktpersonerReportModel>,
        meta: {
          breadcrumbName: "Vandanlæg kontaktpersoner",
          breadcrumbUrl: "Vandanlæg kontaktpersoner"
        }
      },
      {
        path: "/rapport/vandtilladelse",
        component: GenericRapport,
        name: "Vand tilladelse",
        props: {
          reportModel: new VandTilladelseReportModel()
        } as IGenericReportProps<VandTilladelseReportModel>,
        meta: {
          breadcrumbName: "Vand tilladelse",
          breadcrumbUrl: "Vand tilladelse"
        }
      },
      {
        path: "/rapport/vandtilladelseboring",
        component: GenericRapport,
        name: "Vand tilladelse boring",
        props: {
          reportModel: new VandTilladelseBoringReportModel()
        } as IGenericReportProps<VandTilladelseBoringReportModel>,
        meta: {
          breadcrumbName: "Vand tilladelse boring",
          breadcrumbUrl: "Vand tilladelse boring"
        }
      },
      {
        path: "/rapport/vandtilsyn",
        component: GenericRapport,
        name: "Vand Tilsyn",
        props: {
          reportModel: new VandTilsynReportModel()
        } as IGenericReportProps<VandTilsynReportModel>,
        meta: {
          breadcrumbName: "Vand tilsyn",
          breadcrumbUrl: "Vand tilsyn"
        }
      },
      {
        path: "/rapport/vandhaandhaevelse",
        component: GenericRapport,
        name: "Vand håndhævelse",
        props: {
          reportModel: new VandHaandhaevelseReportModel()
        } as IGenericReportProps<VandHaandhaevelseReportModel>,
        meta: {
          breadcrumbName: "Vand håndhævelse",
          breadcrumbUrl: "Vand håndhævelse"
        }
      },
      {
        path: "/rapport/vandoppumpet",
        component: GenericRapport,
        name: "Vand oppumpet",
        props: {
          reportModel: new VandOppumpetReportModel()
        } as IGenericReportProps<VandOppumpetReportModel>,
        meta: {
          breadcrumbName: "Vand oppumpet",
          breadcrumbUrl: "Vand oppumpet"
        }
      },
      {
        path: "/rapport/vandforsyningsfordeling",
        component: GenericRapport,
        name: "Vand forsyningsfordeling",
        props: {
          reportModel: new VandForsyningsfordelingReportModel()
        } as IGenericReportProps<VandForsyningsfordelingReportModel>,
        meta: {
          breadcrumbName: "Vand forsyningsfordeling",
          breadcrumbUrl: "Vand forsyningsfordeling"
        }
      },
      {
        path: "/rapport/boringtilladelse",
        component: GenericRapport,
        name: "Boring tilladelse",
        props: {
          reportModel: new BoringTilladelseReportModel()
        } as IGenericReportProps<BoringTilladelseReportModel>,
        meta: {
          breadcrumbName: "Boring tilladelse",
          breadcrumbUrl: "Boring tilladelse"
        }
      },
      {
        path: "/rapport/boringpejling",
        component: GenericRapport,
        name: "Boring pejling",
        props: {
          reportModel: new VandBoringPejlingReportModel()
        } as IGenericReportProps<VandBoringPejlingReportModel>,
        meta: {
          breadcrumbName: "Boring pejling",
          breadcrumbUrl: "Boring pejling"
        }
      }
    ]
  },
  {
    path: "/frister",
    name: "frister",
    redirect: "/frister/opfoelgning",
    component: PersonligPlanlaegning,
    meta: {
      breadcrumbName: "Frister og status",
      breadcrumbUrl: "frister",
      requiredResource:
        KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED_LAES +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_LANDBRUG_LAES +
        "||" +
        KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG_LAES
    },
    children: [
      {
        path: "opfoelgning",
        name: "opfoelgning",
        component: Opfoelgning,
        meta: {
          breadcrumbName: "Opfølgning",
          breadcrumbUrl: "frister/opfoelgning",
          displayName: "Opfølgningsliste"
        }
      },
      {
        path: "dmastatus",
        name: "dmastatus",
        component: DmaStatus,
        meta: {
          breadcrumbName: "Dma Status",
          breadcrumbUrl: "frister/dmastatus",
          displayName: "Dma Status",
          isTabVisible() {
            const sessionStore = useSessionStore();
            return sessionStore.hasSomeRights([KundeRoleEnums.SAGSBEHANDLER_LANDBRUG, KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED]);
          }
        }
      },
      {
        path: "jupiterstatus",
        name: "jupiterstatus",
        component: JupiterStatus,
        meta: {
          breadcrumbName: "Jupiter Status",
          breadcrumbUrl: "frister/jupiterstatus",
          displayName: "Jupiter Status",
          isTabVisible() {
            const sessionStore = useSessionStore();
            return sessionStore.hasRights(KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG);
          }
        }
      }
    ]
  },
  { path: "/404", name: "404", component: NotFound, meta: { breadcrumbName: "Ikke fundet", breadcrumbUrl: "404" } },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
    meta: { breadcrumbName: "Ikke fundet", breadcrumbUrl: "404" }
    // ]
    // });
  },
  {
    path: "/ingenadgang",
    name: "noaccess",
    component: NoAccess
  }
];

const nonAuthRoutes = ["login", "stslogin", "stserror", "swecologin", "logout"];

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  localStorage.setItem("requestedRoute", to.fullPath);
  if (!localStorage.usertoken && to.name && !nonAuthRoutes.includes(to.name.toString())) {
    return {
      name: "login"
    };
  } else {
    if (localStorage.usertoken && localStorage.goToRouteAfterLogin && !localStorage.goToRouteAfterLogin.includes("login")) {
      const route = localStorage.goToRouteAfterLogin;
      localStorage.removeItem("goToRouteAfterLogin");
      return {
        path: route
      };
    } else {
      if (localStorage.usertoken) {
        const sessionStore = useSessionStore();
        const bruger: Bruger = sessionStore.bruger;
        if (bruger.brugerId === "") {
          await sessionStore.updateActivePrincipal();
        }
      }
      let hasRights = true;
      if (to.meta?.requiredResource) {
        const requiredResources = to.meta.requiredResource.split("||");
        hasRights = false;

        const sessionStore = useSessionStore();
        for (const requiredResource of requiredResources) {
          if (sessionStore.hasRights(requiredResource)) {
            hasRights = true;
            break;
          }
        }
      } else {
        //If requiredResource is not defined on route, then we will inherit the matched ( typical parent ) requiredResources
        const requiredResources = to.matched
          .filter(matched => matched.meta && matched.meta.requiredResource)
          .map(matched => matched.meta.requiredResource);
        hasRights = requiredResources.every(resource => {
          const requiredResources2 = resource.split("||");

          const sessionStore = useSessionStore();
          for (const requiredResource of requiredResources2) {
            if (sessionStore.hasRights(requiredResource)) {
              return true;
            }
          }
          return false;
        });
      }
      if (hasRights) {
        return true;
      } else {
        return {
          path: "/ingenadgang"
        };
      }
    }
  }
});

function setTitle(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  if (to.fullPath.includes("/virksomheder/") || to.fullPath.includes("/landbrug/") || to.fullPath.includes("/vandanlaeg/")) {
    //document.title is handled in VirksomhedDetails.vue / VandanlaegDetails.vue
  } else {
    const sessionStore = useSessionStore();
    const kunde = sessionStore.activeKunde;
    if (kunde && kunde.kundeNavn !== "") {
      const newTitle = `MiljøWeb - ${kunde.kundeNavn}`;
      if (document.title !== newTitle) {
        document.title = newTitle;
      }
    } else {
      document.title = "MiljøWeb";
    }
  }
}

router.afterEach((to, from) => {
  setTitle(to, from);
});

export default router;
