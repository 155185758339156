import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { fetchLookup } from "@/utils/utils";
import { useLookupStore } from "@/stores/lookup";

export default class TilsynTypeFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains", null);

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        label: "Tilsyntype",
        labelAttribute: "navn"
      },
      async mounted() {
        const lookupStore = useLookupStore();

        await fetchLookup("dmalookup/tilsynsTyper", "tilsynTyper");
        this.selectProps.options = lookupStore.tilsynTyper;
      }
    };
  }
}
