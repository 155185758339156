<template>
  <div class="cookies" v-if="showCookiePopup === null">
    <div class="container mw-100">
      <ul class="cookie pt-3">
        <li class="cookie-item">
          <h3 class="text-white">Accept af cookies</h3>
          <p class="text-white">
            Denne side bruger cookies til at forbedre brugeroplevelsen. Ved at trykke acceptér cookies eller ved at fortsætte med at benytte
            siden, accepterer du brugen af cookies.
          </p>
        </li>
      </ul>
      <div class="actions">
        <div class="buttons">
          <MwButton class="btn btn-sm btn-primary" @click="acceptCookie()">Acceptér cookies</MwButton>
          <MwButton class="btn btn-sm btn-primary" @click="showCookiePopup = ''" style="margin-left: 4px">Påmind mig senere</MwButton>
        </div>
        <a class="text-white link" href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies+and+similar+technologies"
          >>> Mere information om cookies</a
        >
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import MwButton from "@/components/mwUtils/MwButton.vue";
import { computed, onMounted, ref, Ref } from "vue";

const cookieValue: Ref<string | null> = ref(localStorage.getItem("acceptCookie"));

const showCookiePopup = computed({
  // getter
  get() {
    return cookieValue.value;
  },
  // setter
  set(newValue) {
    cookieValue.value = newValue;
  }
});

onMounted(() => {
  window.addEventListener("acceptCookie-localstorage-changed", event => {
    cookieValue.value = event.detail.storage;
  });
});

function acceptCookie() {
  localStorage.setItem("acceptCookie", "true");

  window.dispatchEvent(
    new CustomEvent("acceptCookie-localstorage-changed", {
      detail: {
        storage: localStorage.getItem("acceptCookie")
      }
    })
  );
}
</script>
<style scoped>
.cookies {
  position: absolute;
  background: rgba(0, 0, 0, 0.77);
  bottom: 0;
  width: 100%;
  flex-wrap: wrap;
}

.cookie {
  list-style: none;
  display: flex;
}
.buttons {
  display: flex;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-evenly;
}
.link {
  text-decoration: underline;
}
</style>
