<template>
  <MwModal @close="onErrorModalClosed" :header="'Fejl - ' + httpError.timeStamp">
    <fieldset style="margin-bottom: 0">
      <p>
        <label class="h5">{{ httpError.errorModalContent }}</label>
      </p>
      <div v-if="httpError.errorModalShowAdvancedContent">
        <MwCollapseBox
          v-if="httpError.errorModalAdvancedContent !== ''"
          titel="Vis message/detail"
          :collapsed="httpError.errorModalAdvancedContent.length > 75"
        >
          <pre>{{ httpError.errorModalAdvancedContent }}</pre>
        </MwCollapseBox>
        <br />
        <MwCollapseBox v-if="httpError.errorModalStacktrace !== ''" titel="Vis stacktrace" :collapsed="true">
          <pre>{{ httpError.errorModalStacktrace }}</pre>
        </MwCollapseBox>
      </div>
    </fieldset>
  </MwModal>
</template>

<script lang="ts" setup>
import MwModal from "@/components/mwUtils/mwModals/MwModal.vue";
import MwCollapseBox from "@/components/mwUtils/MwCollapseBox.vue";
import { useMainStore } from "@/stores/main";
import { storeToRefs } from "pinia";

const mainStore = useMainStore();
const { httpError, httpErrorModalVisible } = storeToRefs(mainStore);

function onErrorModalClosed() {
  httpErrorModalVisible.value = false;
}
</script>

<style scoped>
pre {
  max-height: 250px;
  white-space: pre-wrap;
  overflow: auto;
}
</style>
