import { createGuid } from "@/utils/utils";

import SelectFilterEditor from "@/components/mwUtils/mwTable/Filters/components/SelectFilterEditor.vue";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
export default class SelectFilter implements IMwTableFilter {
  id: string = "";
  label: string = "";
  serverFilterKey: string = "";
  serverModelPropertyName: string = "";
  active?: boolean | undefined;
  shortLabel: string = "";
  editorComponent = SelectFilterEditor;
  editorProps: any = {};
  modelLabelKey: string = "navn";
  modelAttribute: string = "id";
  model: any;
  forceActive: boolean = false;
  constructor(serverModelPropertyName = "", label = "", shortLabel = "", serverFilterKey = "", forceActive = false) {
    this.id = createGuid();
    this.serverModelPropertyName = serverModelPropertyName.toLowerCase();
    this.serverFilterKey = serverFilterKey.toLowerCase();
    this.shortLabel = shortLabel;
    this.label = label;
    this.model = {};
    this.forceActive = forceActive;
  }

  createMultiFilterURLString() {
    const values = this.model.value
      .map((a: any) => {
        return a[this.modelAttribute];
      })
      .join("|");
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${values})`;
  }

  createSingleFilterURLString() {
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${this.model.value[this.modelAttribute]})`;
  }

  createUrlString() {
    return this.editorProps.selectProps.multiple ? this.createMultiFilterURLString() : this.createSingleFilterURLString();
  }

  createSingleDisplayValue() {
    return `${this.shortLabel}: ${this.model.value[this.modelLabelKey]}`;
  }

  createMultiDisplayValue() {
    const values = this.model.value
      .map((x: any) => {
        return x[this.modelLabelKey];
      })
      .join(", ");

    return `${this.shortLabel}: ${values}`;
  }

  createDisplayValue() {
    return this.editorProps.selectProps.multiple ? this.createMultiDisplayValue() : this.createSingleDisplayValue();
  }

  beforeActivate() {
    const value = this.model.value;

    let isValid = false;

    if (value !== undefined && value !== null) {
      if (Array.isArray(value) || typeof value === "string") {
        isValid = value.length > 0;
      } else {
        isValid = true;
      }
    }
    this.editorProps.errorMessage = isValid ? undefined : `Feltet er påkrævet`;

    return isValid;
  }
}
