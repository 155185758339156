import { createGuid } from "@/utils/utils";
import DateRangeFilterEditor from "@/components/mwUtils/mwTable/Filters/components/DateRangeFilterEditor.vue";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import moment, { Moment } from "moment";

export default class DateRangeFilter implements IMwTableFilter {
  id: string = "";
  label: string = "";
  serverFilterKey: string = "between";
  serverModelPropertyName: string = "";
  shortLabel: string = "";
  editorComponent = DateRangeFilterEditor;
  editorProps: any = {};
  mandatory: boolean = false;
  model: {
    start: Moment | null;
    end: Moment | null;
  } = {
    start: null,
    end: null
  };

  active?: boolean | undefined;
  forceActive?: boolean | undefined;
  requireStartAndEnd: boolean = false;

  constructor(
    serverModelPropertyName: string,
    label: string,
    shortLabel: string,
    mandatory: boolean = false,
    init?: Partial<DateRangeFilter>
  ) {
    this.id = createGuid();
    this.serverModelPropertyName = serverModelPropertyName.toLowerCase();
    this.shortLabel = shortLabel;
    this.label = label;
    this.mandatory = mandatory;
    this.forceActive = mandatory;
    this.editorComponent = DateRangeFilterEditor;
    this.model = {
      start: null,
      end: null
    };

    if (init) {
      Object.assign(this, init);
    }
  }

  createDisplayValue() {
    const start = this.model.start as moment.Moment | null;
    const end = this.model.end as moment.Moment | null;

    moment.locale("da", {
      monthsShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
    });

    const startValue: string | null = start != null ? moment(start).locale("da").format("D MMM YYYY") : null;
    const endValue: string | null = end != null ? moment(end).locale("da").format("D MMM YYYY") : null;

    if (startValue && endValue) {
      return `${this.shortLabel} Mellem: ${startValue} - ${endValue}`;
    }

    if (startValue) {
      return `${this.shortLabel} Efter: ${startValue}`;
    }

    if (endValue) {
      return `${this.shortLabel} Før: ${endValue}`;
    }

    return this.shortLabel;
  }

  createUrlString(): string {
    let start = null;
    let end = null;

    if (this.model.start) {
      const isMoment = moment.isMoment(this.model.start);
      if (!isMoment) {
        start = moment(this.model.start);
      } else {
        start = this.model.start;
      }
    }

    if (this.model.end) {
      const isMoment = moment.isMoment(this.model.end);
      if (!isMoment) {
        end = moment(this.model.end);
      } else {
        end = this.model.end;
      }
    }

    const startValue = start != null ? start.format("DD-MM-YYYY") : "";
    const endValue = end != null ? end.format("DD-MM-YYYY") : "";

    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${startValue}|${endValue})`;
  }

  beforeActivate() {
    let isValid = true;

    if (this.requireStartAndEnd && (!this.model.start || !this.model.end)) {
      isValid = false;
      this.editorProps.errorMessage = "Begge datofelter skal udfyldes";
    } else if (this.model.start == null && this.model.end == null) {
      isValid = false;
      this.editorProps.errorMessage = `Mindst ét datofelt skal udfyldes`;
    }

    return isValid;
  }
}
