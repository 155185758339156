export enum VirksomhedsTypeEnum {
  ALLE = 0,
  VIRKSOMHED = 1,
  LANDBRUG = 2,
  VANDANLAEG = 3
}
export enum VirksomhedsTypeTextEnum {
  VIRKSOMHED = "virksomhed",
  LANDBRUG = "landbrug",
  VANDANLAEG = "vandanlaeg"
}
