import { useToggle } from "@vueuse/core";
import { ref } from "vue";
import { useChangeTrackers } from "@/components/mwUtils/changeTracker/useChangeTrackers";

const [isModalVisible, toggleModal] = useToggle();
const promptResolve: any = ref();
const promptReject: any = ref();
const changeTrackers = useChangeTrackers();

async function prompt(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    promptResolve.value = resolve;
    promptReject.value = reject;
  });
}

async function clearChangesWithPrompt(changeTrackerId?: string) {
  if (trackerHasChangesFor(changeTrackerId)) {
    toggleModal(true);

    const shouldContinue = await prompt();

    toggleModal(false);

    if (shouldContinue) {
      changeTrackers.setShouldReset();
      changeTrackers.clearChanges(changeTrackerId);
    }

    return shouldContinue;
  }

  return true;
}

function trackerHasChangesFor(changeTrackerId?: string) {
  return changeTrackers.hasChanges(changeTrackerId);
}

export { isModalVisible, clearChangesWithPrompt, promptResolve, trackerHasChangesFor };
