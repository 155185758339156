import { Lookup } from "@/models/lookup";
import { VirksomhedsTypeEnum } from "@/models/virksomhed/virksomhedsTypeEnum";
import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";

export default class VirksomhedsTypeFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains", null);
    this.modelAttribute = 'navn'

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Virksomhedstype",
        labelAttribute: "navn",
        options: this.createLookups()
      }
    };

    this.model = {
      value: null
    };
  }

  createLookups() {
    const virksomed = new Lookup();
    virksomed.id = VirksomhedsTypeEnum.VIRKSOMHED.toString();
    virksomed.kode = VirksomhedsTypeEnum.VIRKSOMHED.toString();
    virksomed.navn = "Virksomhed";
    virksomed.beskrivelse = "Virksomhed";

    const landbrug = new Lookup();
    landbrug.id = VirksomhedsTypeEnum.LANDBRUG.toString();
    landbrug.kode = VirksomhedsTypeEnum.LANDBRUG.toString();
    landbrug.navn = "Landbrug";
    landbrug.beskrivelse = "Landbrug";

    const vandanlaeg = new Lookup();
    vandanlaeg.id = VirksomhedsTypeEnum.VANDANLAEG.toString();
    vandanlaeg.kode = VirksomhedsTypeEnum.VANDANLAEG.toString();
    vandanlaeg.navn = "Vandanlæg";
    vandanlaeg.beskrivelse = "Vandanlæg";

    const lookups = [];

    lookups.push(virksomed);
    lookups.push(landbrug);
    lookups.push(vandanlaeg);

    return lookups;
  }
}
