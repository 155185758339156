<template>
  <a :href="aElementUrl" target="_blank" rel="noreferrer" style="cursor: pointer"><img :src="imgUrl" /></a>
</template>

<script lang="ts" setup>
let isTrunk = window.location.hostname.startsWith("miljoeweb.gdev.local");
const isBranch = window.location.hostname.startsWith("tag-miljoeweb.gdev.local");

if (!isTrunk && !isBranch) {
  isTrunk = true;
}

let id = "";

if (isTrunk) {
  id = "MiljoeWeb_Deploy_DeployDevelopmentBuildToMiljoewebGdevLocalWithCypressTest";
} else if (isBranch) {
  id = "MiljoeWeb_Deploy_DeployBranchToBranchWithCypressTestDevelopment";
}

const baseUrl = "http://gdevcbuild03.gdev.local:5050";
const aElementUrl = `${baseUrl}/viewType.html?buildTypeId=${id}`;
const imgUrl = `${baseUrl}/app/rest/builds/buildType:(id:${id}),failedToStart:(any)/statusIcon.svg`;
</script>

<style scoped></style>
