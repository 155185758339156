<template>
  <div class="form-group date-range-arguments">
    <MwDatepicker
      class="datepicker-field"
      v-model="modelValueShadow.start"
      :disabledDates="{
        from: modelValueShadow.end ? new Date(modelValueShadow.end) : ''
      }"
    />
    <span>-</span>
    <MwDatepicker
      class="datepicker-field"
      v-model="modelValueShadow.end"
      :disabledDates="{
        to: modelValueShadow.start ? new Date(modelValueShadow.start) : ''
      }"
    />
  </div>
  <div style="color: #821307" v-if="editorProps.errorMessage">{{ editorProps.errorMessage }}</div>
</template>

<script lang="ts" setup>
import MwDatepicker from "@/components/mwUtils/mwInputs/MwDatepicker.vue";
import { defineProps, reactive, toRaw, watch } from "vue";

interface Props {
  modelValue: { start: any; end: any };
  editorProps: any;
  errorMessage?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (eventName: "update:modelValue", value: any): void;
}>();

const modelValueShadow = reactive(props.modelValue);
watch(
  modelValueShadow,
  v => {
    emit("update:modelValue", toRaw(v));
  },
  { deep: true }
);
</script>

<style scoped>
.date-range-arguments {
  display: flex;
}

.date-range-arguments span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.datepicker-field {
  margin: 0;
}
</style>
