import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";

export default class HasValueFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string, trueDisplaytext: string, falseDisplaytext: string) {
    super(field, title, shortLabel, "contains", null);

    this.editorProps = {
      selectProps: {
        label: "Planlagt",
        labelAttribute: "navn",
        autoSort: true,
        options: [
          { navn: trueDisplaytext, id: true, kode: "1", beskrivelse: trueDisplaytext },
          { navn: falseDisplaytext, id: false, kode: "2", beskrivelse: falseDisplaytext }
        ]
      }
    };
  }

  createUrlString(): string {
    const serverFilterKey = this.model.value ? "nonempty" : "empty";

    return `${this.serverModelPropertyName}:${serverFilterKey}`;
  }

  createDisplayValue() {
    return `${this.shortLabel}: ${this.model.value ? "Ja" : "Nej"}`;
  }
}
