<template>
  <div
    class="h-100 app"
    :class="{ loginBackground: showLoginBackground, fullscreen: sessionStore.isFullscreen }"
    @dragover.prevent="preventDragDrop"
    @drop.prevent="preventDragDrop"
  >
    <div
      class="d-flex h-100 flex-column"
      :class="{
        loginBackgroundPattern: showLoginBackground,
        backgroundGrayscaled: route.name === 'stserror' || route.name === 'logout'
      }"
    >
      <div class="flex-shrink-0"><NavBar ref="navBar" /></div>
      <div class="flex-fill d-flex container-fluid mx-auto main-content" :class="{ fullscreen: sessionStore.isFullscreen }">
        <router-view v-slot="{ Component }">
          <div class="h-100 w-100 d-flex flex-column" style="position: absolute">
            <component :is="Component"></component>
          </div>
        </router-view>
      </div>
      <transition name="slide-fade">
        <div class="flex-shrink-0" v-if="route.name === 'home' || route.name === 'login'">
          <Footer />
        </div>
      </transition>
    </div>
    <CookiesPopup />
    <ChangeTrackerDialog />
    <HttpErrorModal v-if="httpErrorModalVisible" />
    <ForbiddenModal v-if="forbiddenErrorModalVisible" />
    <UserSettingsModal v-if="userSettingsModalVisible" />
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, watch } from "vue";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import CookiesPopup from "./components/CookiesPopup.vue";
import ChangeTrackerDialog from "@/components/ChangeTrackerDialog.vue";
import HttpErrorModal from "@/components/HttpErrorModal.vue";
import ForbiddenModal from "@/components/ForbiddenModal.vue";
import UserSettingsModal from "@/components/UserSettingsModal.vue";
import Elevio from "elevio/lib/client.js";

import { useSessionStore } from "@/stores/session";

import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useMainStore } from "@/stores/main";

const sessionStore = useSessionStore();
const mainStore = useMainStore();

const route = useRoute();

const { userSettingsModalVisible, httpErrorModalVisible, forbiddenErrorModalVisible } = storeToRefs(mainStore);

onMounted(() => {
  if (sessionStore.bruger.brugerId) {
    loadElevio();
  }
});
watch(
  () => sessionStore.bruger.brugerId,
  newBrugerId => {
    if (newBrugerId) {
      loadElevio();
    }
  }
);

const showLoginBackground = computed(() => {
  return route.name == "login" || route.name == "stserror" || route.name == "swecologin" || route.name == "logout";
});

function preventDragDrop(event: DragEvent) {
  event.dataTransfer.dropEffect = "none";
}

function loadElevio() {
  Elevio.load(import.meta.env.VITE_ELEVIOID).then(() => {
    console.log("Elevio has loaded, ready to use!");
  });
}
</script>

<style lang="less">
@import "./assets/css/routingTransitions.less";
html,
body {
  height: 100% !important;
  overflow-y: hidden;
  overflow-x: hidden;
}
@media (max-width: 1000px) {
  body {
    overflow-x: auto;
  }
}

body {
  background: #eeeeee;
}

::selection {
  background: #bebebf;
}

.container-fluid {
  max-width: 1600px;
}

.main-content {
  margin: 30px;
  //margin-top: 0;
}

.main-content:has(.breadcrumb) {
  margin-top: 0px;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  font-weight: inherit !important;
}

.mwContentContainer {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; //Har 30-09-2020 ændret denne fra overlay til auto. Er ikke sikker på konsekvensen, men mozilla stater at den er deprecated. Årsagen er at den nye datepicker ellers ville scrolle med alt indhold når den er åben, og ikke sidder fast på input feltet
}

.well-content {
  padding: 1.9rem;
  margin-bottom: 1.9rem;
  background-color: #e2e0da;
  // background-color: #e2e0da;
  // padding: 10px;
  overflow-y: auto;
}

.default-iconStyle {
  font-size: x-large;
  opacity: 1;
}

.default-iconStyle.active {
  opacity: 0.3;
}

.default-iconStyle:hover:not(.active) {
  cursor: pointer;
}

.default-iconStyle.topRightEditIcon {
  top: -13px;
  position: relative;
  right: -8px;
}

.pointer {
  cursor: pointer;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(0.62, 0.61, 0.62, 0.61);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(80px);
}

.fade-in {
  animation: fadeIn ease 0.8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.check input[type='checkbox'][disabled='disabled'] ~ label, /* Fordi sweco bootstrap er noget ged */
.check input[type='checkbox'][disabled='disabled'] ~ label::before,
.check input[type='checkbox'][disabled='disabled'] ~ label::after {
  color: #a5a5a8 !important;
  cursor: default; /* Ja i sweco bootstrap er der cursor:pointer på disabled inputs */
}

.externallyRequired:hover {
  cursor: default;
}
.externallyRequired {
  left: 2px;
  position: relative;
}
.externallyRequired sup {
  top: -4px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adadad !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #adadad !important;
  color: rgb(211, 211, 211);
}

::-ms-input-placeholder {
  color: #adadad !important;
}

/*SWECO BOOTSTRAP HACK*/
.check input + label:after,
.check input + label:before,
.radio input + label:after,
.radio input + label:before {
  z-index: 5;
}

.aligned-group {
  justify-content: center;
  display: flex;
  align-items: baseline;
  flex-direction: row;
}

.dropdown-item.active,
.dropdown-item:active,
.twitter-typeahead .tt-menu div.active.tt-suggestion,
.twitter-typeahead .tt-menu div.tt-suggestion:active {
  color: #fff !important;
}

.aligned-group .col-3 .form-group {
  margin-bottom: 0;
}

.aligned-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tipLink {
  color: #b586a4 !important;
}

.col-1-5 {
  flex: 0 0 12.3%;
  max-width: 12.3%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

label {
  word-break: break-word;
  margin-bottom: 0.3rem;
}

.dropdown-menu .dropdown-item:hover {
  cursor: pointer;
}

.checkboxHeadingBaselined {
  margin-top: -10px;
}

.modal-dynamic {
  max-width: 50% !important;
}

.modal-medium {
  max-width: 650px;
}

.btn-sub {
  background-color: #237b5e !important;
  color: #fff;
}

.btn.btn-sub:hover:not([disabled]) {
  background-color: #126046 !important;
  color: #fff;
}

.btn.deleteButton:is([disabled]) {
  opacity: 0.6;
}

.btn.deleteButton:hover:not([disabled]) {
  background-color: #821307 !important;
  color: white;
  opacity: 1;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: #a5a5a8;
  display: inline-block;
  margin-bottom: 1.8rem;
  margin-top: 1.8rem;
}

.hr.top {
  margin-bottom: 2rem;
  margin-top: 0rem;
}

.changesDetected {
  height: 40px;
  font-size: 12pt;
}

.vdp-datepicker__clear-button {
  position: absolute;
  bottom: 10px;
  right: 12px;
  opacity: 0.3;
}

@media (max-width: 1260px) {
  .vdp-datepicker__clear-button {
    bottom: 0px;
    right: 5px;
  }
}

.hr.top + .editIconContainer {
  margin-top: -25px;
  margin-bottom: 10px;
}

.nav-spacing {
  padding-bottom: 10px;
}

.app.fullscreen {
  background: white;
}

.main-content {
  transition: all 0.4s ease;
}

.main-content.fullscreen {
  padding: 0 !important;
  max-width: 100% !important;
  margin-bottom: 0px !important;
  transition: all 0.4s ease;
}

.btn.btn-icon > .fa,
.btn > .close-icon,
.btn > .fab,
.btn > .fal,
.btn > .far,
.btn > .fas {
  margin-right: 0.475rem;
}
</style>
