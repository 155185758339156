/**
 * Used to descripes different report types.
 */
// Should match backend ReportType enum where the value is the name of the enum entry.
export enum ReportType {
  Undefined = "undefined",
  Tilsyn = "tilsyn",
  VandTilladelse = "VandTilladelse",
  VandTilladelseBoring = "VandTilladelseBoring",
  VandanlaegKontaktpersoner = "VandanlaegKontaktpersoner",
  VandTilsyn = "VandTilsyn",
  VandHaandhaevelse = "VandHaandhaevelse",
  VandOppumpet = "VandOppumpet",
  VandForsyningsfordeling = "VandForsyningsfordeling",
  BoringTilladelse = "BoringTilladelse",
  VandBoringPejling = "VandBoringPejling"
}
