import moment from "moment";

export default class JupiterStatus {
  id: string = '';
  jupiterIndberetningsFejlTypeKode: string = '';
  jupiterIndberetningsFejlTypeText: string = '';
  jupiterIndberetningsFejlStatus: string = '';
  jupiterIndberetningsFejlAntal: string = '';
}

export class JupiterStatusType {
  jupiterIndberetningsFejlTypeText: string = '';
  jupiterIndberetningsFejlTypeKode: string = '';
  jupiterIndberetningsFejlStatus: string = '';
  elementStatusListe: JupiterStatusTypeElement[] = [];
}
export class JupiterStatusTypeElement {
  virksomhedId: string = '';
  elementId: string = '';
  virksomhedNavn: string = '';
  elementnavn: string = '';
  fejlTekst: string = '';
  fejltidspunkt: moment.Moment = moment();
}

export class JupiterStatusResendElement {
  typeKode: string = '';
  elementListe: string[] = [];
}
