import { formatBooleanJaNej, formatDate } from "@/filters/mwTableFormatters";
import { IMWReportColumn } from "./IMWReportColumn";
import { ReportType } from "./ReportType";
import { IReportModel } from "./IReportModel";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";

import { nameof } from "@/utils/utils";
import JupiterTilladelsestypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterTilladelsestypeFilter";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import Indtagsanvendelse from "@/components/vandanlaegDetails/tabs/boringer/Indtagsanvendelse.vue";

export class VandBoringPejlingReportModel implements IReportModel<VandBoringPejlingReportModel> {
  aarstal?: number;
  maaned?: number;
  pejletidspunkt?: Date;
  antal?: number;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  anlaegsnavn?: string;
  virksomhedsAdresse?: string;
  cvrnr?: string;
  pnr?: string;
  primaerVirksomhedstype?: string;
  primaerVirksomhedstypeId?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegJupiterId?: number;
  anlaegsart?: string;
  anlaegsartId?: string;
  anlaegsnr?: string;
  aktivStatus?: string;
  aktivStatusId?: string;
  dguNumre?: string;
  boringDGUnr?: string;
  indtagsnr?: string;
  grundvandskote?: number;
  terraenkote?: number;
  pejlemetode?: string;
  feltmaaling?: number;
  roPeriode?: number;
  indtagsanvendelse?: string;
  pejleSituation?: string;
  boringsanvendelse?: string;
  pejlingJupiterId?: number;

  get columnModel(): IMWReportColumn<VandBoringPejlingReportModel>[] {
    return [
      {
        id: "aarstal",
        label: "Årstal",
        show: true
      },
      {
        id: "maaned",
        label: "Måned",
        show: true
      },
      {
        id: "pejletidspunkt",
        label: "Pejletidspunkt",
        show: true,
        formatter: formatDate
      },
      {
        id: "anlaegStartdato",
        label: "Anlæg Startdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegSlutdato",
        label: "Anlæg Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegsnavn",
        label: "Anlægsnavn",
        show: false
      },
      {
        id: "virksomhedsAdresse",
        label: "Anlægsadresse",
        show: false
      },
      {
        id: "cvrnr",
        label: "CVR-nummer",
        show: false
      },
      {
        id: "pnr",
        label: "P-nummer",
        show: false
      },
      {
        id: "primaerVirksomhedstype",
        label: "Primær virksomhedstype",
        show: false
      },
      {
        id: "oevrigeVirksomhedstyper",
        label: "Øvrige virksomhedstyper",
        show: false,
        sort: false
      },
      {
        id: "anlaegsart",
        label: "Anlægsart",
        show: false
      },
      {
        id: "aktivStatus",
        label: "Anlæg aktivstatus",
        show: false
      },
      {
        id: "anlaegsnr",
        label: "Anlægsnummer",
        show: false
      },
      {
        id: "anlaegJupiterId",
        label: "JupiterId for anlæg",
        show: false
      },
      {
        id: "boringDGUnr",
        label: "DGU nr for boring",
        show: false
      },
      {
        id: "dguNumre",
        label: "DGU numre for anlæg",
        show: false,
        sort: false
      },
      {
        id: "indtagsnr",
        label: "Indtag",
        show: false
      },
      {
        id: "grundvandskote",
        label: "Grundvandskote",
        show: false
      },
      {
        id: "terraenkote",
        label: "Terrænkote",
        show: false
      },
      {
        id: "pejlemetode",
        label: "Metode",
        show: false
      }, 
      {
        id: "roPeriode",
        label: "Ro periode",
        show: false
      }, 
      {
        id: "feltmaaling",
        label: "Måling",
        show: false
      }, 
      {
        id: "indtagsanvendelse",
        label: "Indtagsanvendelse",
        show: false
      }, 
      {
        id: "pejleSituation",
        label: "Situation",
        show: false
      },
      {
        id: "boringsanvendelse",
        label: "Boringsanvendelse",
        show: false
      },
      {
        id: "pejlingJupiterId",
        label: "JupiterId for pejling",
        show: false
      },
      {
        id: "antal",
        label: "Antal",
        show: false
      }
    ];
  }

  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<VandBoringPejlingReportModel>("pejletidspunkt"), "Pejletidspunkt", "Pejletidspunkt"),
      new JupiterAktivStatusFilter(nameof<VandBoringPejlingReportModel>("aktivStatusId"), "Anlæg aktivstatus", "Anlæg aktivstatus"),
      new JupiteranlaegsartFilter(nameof<VandBoringPejlingReportModel>("anlaegsartId"), "Anlægsart", "Anlægsart"),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<VandBoringPejlingReportModel>("primaerVirksomhedstypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper")
    ];
  }
  get reportType(): ReportType {
    return ReportType.VandBoringPejling;
  }
}
