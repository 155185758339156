import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import { ReportType } from "./ReportType";
import { formatDate } from "@/filters/mwTableFormatters";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import { nameof } from "@/utils/utils";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import JupiterForsyningsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterForsyningsartFilter";

export class VandForsyningsfordelingReportModel implements IReportModel<VandForsyningsfordelingReportModel> {
  aarstal?: number;
  maaned?: number;
  startdato?: Date;
  slutdato?: Date;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  anlaegsnavn?: string;
  anlaegsadresse?: string;
  cvrNr?: string;
  pnr?: string;
  anlaegsart?: string;
  anlaegsartId?: string;
  primaerVirksomhedstype?: string;
  primaerVirksomhedstypeId?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegsnr?: string;
  anlaegJupiterId?: number;
  jupiterAktivStatus?: string;
  jupiterAktivStatusId?: string;
  
  forsyningsartNavn?: string;
  forsyningsartId?: string;

  maalerstandStart?: number;
  maalerstandSlut?: number;
  omregningsfaktor?: number;

  oppumpetMaengde?: number;

  forsyningsartMaengde?: number;
  sumForsyningsartMaengde?: number;
  antal?: number;


  get columnModel(): IMWReportColumn<VandForsyningsfordelingReportModel>[] {
    return [
      { id: "aarstal", label: "Årstal", show: true },
      { id: "maaned", label: "Måned", show: true },
      { id: "startdato", label: "Startdato", show: true, formatter: formatDate },
      { id: "slutdato", label: "Slutdato", show: false, formatter: formatDate },
      { id: "anlaegStartdato", label: "Anlæg startdato", show: false, formatter: formatDate },
      { id: "anlaegSlutdato", label: "Anlæg slutdato", show: false, formatter: formatDate },
      { id: "anlaegsnavn", label: "Anlægsnavn", show: false },
      { id: "anlaegsadresse", label: "Anlægsadresse", show: false },
      { id: "cvrNr", label: "CVR-nummer", show: false },
      { id: "pnr", label: "P-nummer", show: false },
      { id: "anlaegsart", label: "Anlægsart", show: true },
      { id: "primaerVirksomhedstype", label: "Primær virksomhedstype", show: false },
      { id: "oevrigeVirksomhedstyper", label: "Øvrige virksomhedstyper", show: true, sort: false },      
      { id: "anlaegsnr", label: "Anlægsnummer", show: false },
      { id: "anlaegJupiterId", label: "Jupiter ID", show: true },
      { id: "jupiterAktivStatus", label: "Anlæg aktivstatus", show: true },
      { id: "oppumpetMaengde", label: "Oppumpet mængde m3", show: false },
      { id: "maalerstandStart", label: "Målerstart", show: false },
      { id: "maalerstandSlut", label: "Målerslut", show: false },
      { id: "omregningsfaktor", label: "Omregningsfaktor", show: false },
      { id: "forsyningsartNavn", label: "Forsyningsart", show: false },
      { id: "forsyningsartMaengde", label: "Mængde m3", show: true },
      { id: "sumForsyningsartMaengde", label: "Sum mængde m3", show: true },
      { id: "antal", label: "Antal", show: true }
    ];
  }
  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<VandForsyningsfordelingReportModel>("startdato"), "Dato interval", "Dato interval"),
      new JupiteranlaegsartFilter(nameof<VandForsyningsfordelingReportModel>("anlaegsartId"), "Anlægsart", "Anlægsart"),
      new JupiterForsyningsartFilter(nameof<VandForsyningsfordelingReportModel>("forsyningsartId"), "Forsyningsart", "Forsyningsart"),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<VandForsyningsfordelingReportModel>("primaerVirksomhedstypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper"),
      new JupiterAktivStatusFilter(nameof<VandForsyningsfordelingReportModel>("jupiterAktivStatusId"), "Anlæg aktivstatus", "Anlæg aktivstatus")
    ];
  }
  get reportType(): ReportType {
    return ReportType.VandForsyningsfordeling;
  }
}
