import { formatBooleanGaeldendeTilladelse, formatBooleanJaNej, formatDate } from "@/filters/mwTableFormatters";
import { IMWReportColumn } from "./IMWReportColumn";
import { ReportType } from "./ReportType";
import { IReportModel } from "./IReportModel";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";

import { nameof } from "@/utils/utils";
import VandTilladelseAktivFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandTilladelseAktivFilter";
import JupiterTilladelsestypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterTilladelsestypeFilter";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";

export class VandTilladelseReportModel implements IReportModel<VandTilladelseReportModel> {
  aarstal?: number;
  maaned?: number;
  slutAarstal?: number;
  slutMaaned?: number;
  startdato?: Date;
  slutdato?: Date;
  aktivStatus?: boolean;
  antal?: number;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  anlaegsnavn?: string;
  virksomhedsAdresse?: string;
  cvrnr?: string;
  pnr?: string;
  primaerVirksomhedstype?: string;
  primaerVirksomhedstypeId?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegsart?: string;
  anlaegsartId?: string;
  tilladelseJupiterId?: number;
  anlaegsnr?: string;
  jupiterAktivStatus?: string;
  jupiterAktivStatusId?: string;
  anlaegJupiterId?: number;
  vandtype?: string;
  kilde?: string;
  indvindingsformaal?: string;
  tilladelsestype?: string;
  tilladelsestypeId?: string;
  tilladtVandmaengdeM3AarAnlaeg?: number;
  tilladtVandmaengdeM3TimeAnlaeg?: string;
  journalnr?: string;
  tilbagekaldt?: string;
  tilbagekaldtdato?: string;
  dguNumre?: string;
  sumTilladtVandmaengdeM3AarAnlaeg?: string;

  get columnModel(): IMWReportColumn<VandTilladelseReportModel>[] {
    return [
      {
        id: "aarstal",
        label: "Start år",
        show: true
      },
      {
        id: "maaned",
        label: "Start måned",
        show: true
      },
      {
        id: "slutAarstal",
        label: "Slut år",
        show: true
      },
      {
        id: "slutMaaned",
        label: "Slut måned",
        show: true
      },
      {
        id: "startdato",
        label: "Startdato",
        show: true,
        formatter: formatDate
      },
      {
        id: "slutdato",
        label: "Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "aktivStatus",
        label: "Gældende tilladelse",
        show: false,
        formatter: formatBooleanGaeldendeTilladelse
      },
      {
        id: "anlaegStartdato",
        label: "Anlæg Startdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegSlutdato",
        label: "Anlæg Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegsnavn",
        label: "Anlægsnavn",
        show: false
      },
      {
        id: "virksomhedsAdresse",
        label: "Anlægsadresse",
        show: false
      },
      {
        id: "cvrnr",
        label: "CVR-nummer",
        show: false
      },
      {
        id: "pnr",
        label: "P-nummer",
        show: false
      },
      {
        id: "anlaegsnr",
        label: "Anlægsnummer",
        show: false
      },
      {
        id: "anlaegJupiterId",
        label: "Jupiter ID",
        show: true
      },
      {
        id: "primaerVirksomhedstype",
        label: "Primær virksomhedstype",
        show: false
      },
      {
        id: "oevrigeVirksomhedstyper",
        label: "Øvrige virksomhedstyper",
        show: false,
        sort: false
      },
      {
        id: "anlaegsart",
        label: "Anlægsart",
        show: false
      },
      {
        id: "tilladelseJupiterId",
        label: "TilladelsesId",
        show: false
      },
      {
        id: "jupiterAktivStatus",
        label: "Anlæg aktivstatus",
        show: false
      },
      {
        id: "vandtype",
        label: "Vandtype",
        show: true
      },
      {
        id: "kilde",
        label: "Kilde",
        show: false
      },
      {
        id: "indvindingsformaal",
        label: "Indvindingsformål",
        show: false
      },
      {
        id: "tilladelsestype",
        label: "Tilladelsestype",
        show: true
      },
      {
        id: "journalnr",
        label: "Journalnr",
        show: false
      },
      {
        id: "tilbagekaldt",
        label: "Tilbagekaldt",
        show: false,
        formatter: formatBooleanJaNej
      },
      {
        id: "tilbagekaldtdato",
        label: "Tilbagekaldtdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "dguNumre",
        label: "DGU numre for anlæg",
        show: false,
        sort: false
      },
      {
        id: "tilladtVandmaengdeM3AarAnlaeg",
        label: "Tilladt mængde m3/år for anlæg",
        show: false
      },
      {
        id: "tilladtVandmaengdeM3TimeAnlaeg",
        label: "Tilladt mængde m3/time for anlæg",
        show: false
      },
      {
        id: "sumTilladtVandmaengdeM3AarAnlaeg",
        label: "Sum tilladt mængde m3/år for anlæg",
        show: false
      },
      {
        id: "antal",
        label: "Antal",
        show: false
      }
    ];
  }

  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<VandTilladelseReportModel>("startdato"), "Startdato", "Startdato"),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<VandTilladelseReportModel>("primaerVirksomhedstypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new DateRangeFilter(nameof<VandTilladelseReportModel>("slutdato"), "Slutdato", "Slutdato"),
      new JupiteranlaegsartFilter(nameof<VandTilladelseReportModel>("anlaegsartId"), "Anlægsart", "Anlægsart"),
      new VandTilladelseAktivFilter(nameof<VandTilladelseReportModel>("aktivStatus"), "Gældende tilladelse", "Gældende tilladelse"),
      new JupiterAktivStatusFilter(nameof<VandTilladelseReportModel>("jupiterAktivStatusId"), "Anlæg aktivstatus", "Anlæg aktivstatus"),
      new JupiterTilladelsestypeFilter(nameof<VandTilladelseReportModel>("tilladelsestypeId"), "Tilladelsestype", "Tilladelsestype"),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper")
    ];
  }
  get reportType(): ReportType {
    return ReportType.VandTilladelse;
  }
}
