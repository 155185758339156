<template>
  <div class="navbar navbar-south">
    <div class="container d-flex justify-content-center mw-100">
      <ul class="nav pt-3">
        <li class="nav-item">
          <img :src="swecoLogo" class="logo" alt="Sweco logo" height="30" width="103" />
        </li>
        <li class="nav-item text-white" style="top: 5px; position: relative">© 2023 Sweco AB</li>
      </ul>
      <div class="position-absolute float-right version" style="display: flex">
        <BuildStatus class="mr-2" v-if="hostname != '' && hostname != 'miljoeweb.dk' && hostname != 'www.miljoeweb.dk'" />
        <h5 class="gray-light-color">MiljøWeb v{{ version }}</h5>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import UrlBuilder from "@/utils/networking/urlBuilder";
import swecoLogo from "@/assets/images/swecoLogo.png";
import { fetchGet } from "@/utils/networking/httpclient";
import BuildStatus from "@/components/BuildStatus.vue";

const version = ref("");

const urlBuilder = new UrlBuilder("Login/Version");
const url = urlBuilder.generateUrlWithParams();
const hostname = window.location.hostname;

onMounted(async () => {
  const { status, data } = await fetchGet(url);

  version.value = data.version + " - " + data.svnVersion;
});
</script>

<style scoped>
.navbar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.navbar .logo {
  height: 30px;
  position: relative;
  top: -3px;
}
.navbar .version {
  right: 30px;
  top: 20px;
}
.navbar .version h5 {
  text-transform: inherit;
}
</style>
