import { formatBoolean, formatDate, formatKategori } from "@/filters/mwTableFormatters";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import VirksomhedsTypeVirksomhedLandbrugFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VirksomhedsTypeVirksomhedLandbrugFilter";
import { ReportType } from "./ReportType";
import HovedaktivitetFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/HovedaktivitetFilter";
import { nameof } from "@/utils/utils";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import VirksomhedsgruppeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VirksomhedsgruppeFilter";
import TilsynTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/TilsynTypeFilter";
import SagsbehandlereFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/SagsbehandlereFilter";
import DmaStatusIdFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/DmaStatusIdFilter";
import TilsynsKategoriFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/TilsynsKategoriFilter";
import VirksomhedKategoriFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VirksomhedKategoriFilter";

export class TilsynReportModel implements IReportModel<TilsynReportModel> {
  aarstal?: number;
  dato?: Date;
  ophoertDato?: Date;
  virksomhedsnavn?: string;
  virksomhedsadresse?: string;
  cvrNummer?: string;
  pNummer?: string;
  chrNummer?: string;
  hovedaktivitet?: string;
  hovedAktivitetId?: string;
  biaktiviteter?: string;
  virksomhedskategori?: string;
  virksomhedsgruppe?: string;
  virksomhedsgruppeId?: string;
  virksomhedstype?: string;
  omfattetAfVOC?: boolean;
  risikoVirksomhed?: boolean;
  dmaAktiv?: string;
  dmaStatusId?: string;
  tilsynskategori?: string;
  tilsynstype?: string;
  varslet?: boolean;
  planlagtStart?: Date;
  planlagtSlut?: Date;
  baggrundForTilsyn?: string;
  sagsbehandler?: string;
  antal?: number;
  filIDMA?: boolean;
  tilsynTypeId?: string;
  tilsynId?: string;
  sagsbehandlerId?: string;

  get columnModel(): IMWReportColumn<TilsynReportModel>[] {
    return [
      {
        id: "aarstal",
        label: "Årstal",
        show: true
      },
      {
        id: "dato",
        label: "Dato",
        show: false,
        formatter: formatDate
      },
      {
        id: "ophoertDato",
        label: "Ophørt dato",
        show: false,
        formatter: formatDate
      },
      {
        id: "virksomhedsnavn",
        label: "Virksomhedsnavn",
        show: false
      },
      {
        id: "virksomhedsadresse",
        label: "Virksomhedsadresse",
        show: false
      },
      {
        id: "cvrNummer",
        label: "CVR-nummer",
        show: false
      },
      {
        id: "pNummer",
        label: "P-nummer",
        show: false
      },
      {
        id: "chrNummer",
        label: "CHR-nummer",
        show: false
      },
      {
        id: "hovedaktivitet",
        label: "Hovedaktivitet",
        show: false
      },
      {
        id: "biaktiviteter",
        label: "Biaktiviteter",
        sort: false,
        show: false,
      },
      {
        id: "virksomhedskategori",
        label: "Virksomhedskategori",
        show: false,
        formatter: formatKategori
      },
      {
        id: "virksomhedsgruppe",
        label: "Virksomhedsgruppe",
        show: false
      },
      {
        id: "virksomhedstype",
        label: "Virksomhedstype",
        show: false
      },
      {
        id: "omfattetAfVOC",
        label: "Omfattet af VOC",
        show: false,
        formatter: formatBoolean
      },
      {
        id: "risikoVirksomhed",
        label: "Risikovirksomhed",
        show: false,
        formatter: formatBoolean
      },
      {
        id: "dmaAktiv",
        label: "Aktiv i DMA",
        show: false
      },
      {
        id: "tilsynskategori",
        label: "Tilsynskategori",
        show: true
      },
      {
        id: "tilsynstype",
        label: "Tilsynstype",
        show: false
      },
      {
        id: "varslet",
        label: "Varslet",
        show: false,
        formatter: formatBoolean
      },
      {
        id: "planlagtStart",
        label: "Planlagt start",
        show: false,
        formatter: formatDate
      },
      {
        id: "planlagtSlut",
        label: "Planlagt slut",
        show: false,
        formatter: formatDate
      },
      {
        id: "baggrundForTilsyn",
        label: "Baggrund for tilsynet",
        show: false
      },
      {
        id: "sagsbehandler",
        label: "Sagsbehandler",
        show: false
      },
      {
        id: "antal",
        label: "Antal",
        show: true
      },
      {
        id: "filIDMA",
        label: "Fil i DMA",
        show: false,
        formatter: formatBoolean
      }
    ];
  }
  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new VirksomhedsTypeVirksomhedLandbrugFilter(nameof<TilsynReportModel>("virksomhedstype"), "Virksomhedstype", "Virksomhedstype"),
      new HovedaktivitetFilter(nameof<TilsynReportModel>("hovedAktivitetId"), "Hovedaktivitet", "Hovedaktivitet"),
      new DateRangeFilter(nameof<TilsynReportModel>("dato"), "Datointerval", "Datointerval"),
      new VirksomhedsgruppeFilter(nameof<TilsynReportModel>("virksomhedsgruppeId"), "Virksomhedsgruppe", "Virksomhedsgruppe"),
      new TilsynTypeFilter(nameof<TilsynReportModel>("tilsynTypeId"), "Tilsynstype", "Tilsynstype"),
      new DateRangeFilter(nameof<TilsynReportModel>("ophoertDato"), "Ophørt Interval", "Ophørt Interval"),
      new SagsbehandlereFilter(nameof<TilsynReportModel>("sagsbehandlerId"), "Virksomhedlandbrug"),
      new DmaStatusIdFilter(nameof<TilsynReportModel>("dmaStatusId"), "Aktiv i DMA", "Aktiv i DMA"),
      new TilsynsKategoriFilter(nameof<TilsynReportModel>("tilsynskategori"), "Tilsynskategori", "Tilsynskategori"),
      new VirksomhedKategoriFilter(nameof<TilsynReportModel>("virksomhedskategori"), "Virksomhedskategori", "Virksomhedskategori")
    ];
  }
  get reportType(): ReportType {
    return ReportType.Tilsyn;
  }
}
