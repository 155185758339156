import { fetchGet } from "@/utils/networking/httpclient";
import SelectFilter from "../SelectFilter";

export default class JupiterKontaktpersonTypeFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains", null);
    this.modelAttribute = "id";
    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Kontaktperson",
        labelAttribute: "navn"
      },
      async mounted() {
        const { data } = await fetchGet("KontaktpersonType/listjupiter");
        this.selectProps.options = data;
      }
    };
  }
}
