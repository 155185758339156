import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import { createPinia } from "pinia";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import vueSdp from "@amt-dk/vue-sdp";

// const MwSpinner = () => import('@/components/mwUtils/MwSpinner.vue');
// const MwToast = () => import('@/components/mwUtils/MwToast.vue');
// const MwIcon = () => import('@/components/mwUtils/MwIcon.vue');

const app = createApp(App);

app.use(vueSdp);

//Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

// Vue.component('MwIcon', MwIcon);
// Vue.component('MwToast', MwToast);

const getContainer = () => document.querySelector(".main-content");

function asyncGetContainer() {
  return new Promise(resolve => {
    const observer = new MutationObserver(function (mutations, me) {
      const myContainer = document.querySelector(".main-content");
      if (myContainer) {
        me.disconnect();
        resolve(myContainer);
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  });
}

// forhindre i at blive spammet med toasts
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    return false;
  }
  return toast;
};

app.use(Toast, {
  container: asyncGetContainer,
  filterBeforeCreate
});

app.use(router);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(pinia);

router
  .isReady()
  .then(() => {
    app.mount("#app");
  })
  .catch(err => {
    console.error(err);
  });

// const sessionStore = useSessionStore();
// const lookupStore = useLookupStore();
// const vandanlaegLookupStore = useVandanlaegLookupStore();

/**
 * piniaInstance skal kun bruges de steder hvor useStore() ikke selv auto injecter pinia
 * https://pinia.vuejs.org/core-concepts/outside-component-usage.html#using-a-store-outside-of-a-component
 */
// export { pinia as piniaInstance };
