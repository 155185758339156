import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { fetchGet } from "@/utils/networking/httpclient";
import { Lookup } from "@/models/lookup";

export default class VirksomhedKategoriFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains", null);

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Kategori",
        labelAttribute: "navn",
        options: []
      },
      async mounted() {
        const { data } = await fetchGet("dmalookup/virksomhedsgrupper/");

        const options: any[] = [];

        data.forEach((gruppe: Lookup) => {
          //Tilføj kun kategorier med et kategori navn, samt kun kategorier hvis navn ikke allerede forekommer
          if (options.filter((x: any) => x.navn === gruppe.navn).length === 0 && gruppe.navn !== "") {
            options.push(gruppe);
          }
        });
        this.selectProps.options = options;
      }
    };
  }

  createUrlString(): string {
    const kategorier: string[] = [];
    this.model.value.forEach((value: any) => {
      switch (value.navn.toLowerCase()) {
        case "ingen":
          kategorier.push("3_");
          break;
        case "2":
          kategorier.push("2_");
          break;
        default:
          kategorier.push(value.navn);
          break;
      }
    });

    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${kategorier.join("|")})`;
  }
}
