<template>
  <li class="nav-item dropdown" v-if="favorites.length > 0">
    <a
      class="nav-link nav-start dropdown-toggle"
      href="#"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="onMenuClicked"
    >
      <MwIcon icon="heart" />
      Favoritter
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <div style="max-height: 400px; overflow: auto">
        <button
          type="button"
          class="dropdown-item"
          v-for="(favorite, favoriteindex) in favorites"
          :key="favoriteindex"
          @click="goToVirksomhed(favorite.virksomhedId)"
        >
          <MwIcon :icon="getIconName(favorite.virksomhedType)" />
          {{ favorite.displayName
          }}<span v-if="sessionStore.brugerSettings.visFavoritAdresser">
            - <template v-if="showAddress">{{ getAddress(favorite.virksomhedId) }}</template></span
          >
        </button>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import Favorite from "../../models/favorite";
import { useSessionStore } from "@/stores/session";
import { nextTick, ref } from "vue";
import { storeToRefs } from "pinia";
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import { useRoute, useRouter } from "vue-router";

const adresser: { [key: string]: string } = {};
const sessionStore = useSessionStore();
const showAddress = ref(true);

const router = useRouter();
const route = useRoute();
const { favorites } = storeToRefs(sessionStore);

function getAddress(virksomhedId: string) {
  //console.log(adresser);
  return adresser[virksomhedId];
}

async function onMenuClicked() {
  if (sessionStore.brugerSettings.visFavoritAdresser) {
    const favoritesToBeUpdated = favorites.value
      .filter(favorite => !getAddress(favorite.virksomhedId))
      .map(async favorite => {
        return getVirksomhedAdresse(favorite);
      });

    await Promise.all(favoritesToBeUpdated);

    //TODO Adresser skal være en del af favorit modellen. SÅ vi slipper for det her hack, se MILDEV-3270
    showAddress.value = false;
    await nextTick();
    showAddress.value = true;
  }
}

async function getVirksomhedAdresse(favorite: Favorite) {
  const { data } = await sessionStore.fetchAdresse(favorite.virksomhedId);
  if (data) {
    adresser[favorite.virksomhedId] = data.adresse;
  } else {
    console.warn(`En adresse kunne ikke hentes fra ${favorite.virksomhedId}`);
    adresser[favorite.virksomhedId] = "";
  }
}

async function goToVirksomhed(virksomhedId: string) {
  if (route.params.id == virksomhedId) {
    return;
  }

  const favorite = favorites.value.find(item => item.virksomhedId == virksomhedId);

  if (!favorite) {
    throw new Error("Cannot navigate to favorite with ID: " + virksomhedId + ". No favorites corresponds to that ID.");
  }

  const virksomhedType = favorite.virksomhedType.toLowerCase();
  let routeName = "";

  if (virksomhedType === "vandanlaeg") {
    routeName = "vandanlaeg-stamoplysninger";
  } else if (virksomhedType === "virksomhed") {
    routeName = "virksomhed-stamoplysninger";
  } else if (virksomhedType === "landbrug") {
    routeName = "landbrug-stamoplysninger";
  }

  await router.push({
    name: routeName,
    params: {
      id: virksomhedId
    }
  });
}

function getIconName(virksomhedType: string) {
  switch (virksomhedType) {
    case "Virksomhed":
      return "building";
    case "Landbrug":
      return "farm";
    case "Vandanlaeg":
      return "tint";
    default:
      return "";
  }
}
</script>
<style scoped>
.fa {
  width: 18px;
}
</style>
