import SelectFilter from "../SelectFilter";

export default class JupiterKontaktPersontypeAktivFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "equals");

    this.editorProps = {
      selectProps: {
        label: "Kontakttype aktiv",
        autoSort: false,
        options: [
          { navn: "Aktiv", id: true, kode: "1", beskrivelse: "Kontaktperson uden udløbet slutdato" },
          { navn: "Inaktiv", id: false, kode: "2", beskrivelse: "Kontaktperson med udløbet slutdato" }
        ]
      }
    };
  }

  createUrlString(): string {
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${this.model.value})`;
  }

  createDisplayValue() {
    return `${this.shortLabel}: ${this.model.value ? "Aktiv" : "Inaktiv"}`;
  }
}
