import moment from "moment";
import { Lookup } from "@/models/lookup";

export class Basis {
  id: string = "";
  anlaegsnavn: string = "";
  primaerVirksomhedstype: string = "";
  oevrigeVandanlaegVirksomhedstyper: Virksomhedstype[] = [];
  jupiterId: number = 0;
  anlaegsnr: string = "";
  sagsbehandlerListe: Lookup[] = [];
  jupiterAktivStatusId: string = "";
  startdato: moment.Moment = moment();
  slutdato: moment.Moment = moment();
  jupiterAnlaegsartId: string = "";
  jupiterVandtypeId: string = "";
  jupiterIndvindingsformaalId: string = "";
  jupiterCTRLPROGRAMId: string = "";
  vandmaengdeM3Aar: string = "";
  udloebsdatoPaaGaeldendeTilladelse: moment.Moment = moment();
  overanlaegVirksomhedId: string = "";
  udledning: string = "";
  hjemmeside: string = "";
  jupiterBemaerkning: string = "";
  bemaerkning: string = "";
  beliggenhedsKommuneId: string = "";
  analysegodkendeKommuneId: string = "";
  journalNr: string = "";
  jupiterIndberetningStatus: boolean = false;
  indberetning: boolean = false;
  slettet: boolean = false;
  virksomhedstype: string = "";
  cvrNr: string = "";
  pNr: string = "";
}

class Virksomhedstype {
  id: string = "";
  prioritet: number = 0;
}
