import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { useLookupStore } from "@/stores/lookup";
import { fetchLookup } from "@/utils/utils";

export default class TilsynsKategoriFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains", null);
    this.modelAttribute = 'navn';

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        label: "Tilsynkategori",
        labelAttribute: "navn"
      },
      async mounted() {
        const lookupStore = useLookupStore();

        await fetchLookup("dmalookup/tilsynsKategorier", "tilsynKategorier");
        this.selectProps.options = lookupStore.tilsynKategorier;
      }
    };
  }
}
