import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { useSessionStore } from "@/stores/session";
import { fetchGet } from "@/utils/networking/httpclient";
import { useVuelidateCustom } from "@/composables/useVuelidateCustom";
import { required } from "@/utils/validators";
import { reactive, ref, watch } from "vue";
import { Lookup } from "@/models/lookup";

export default class SagsbehandlereFilter extends SelectFilter {
  filterOnName: boolean;
  vuelidate: any;
  model: { value: Lookup[] };
  constructor(
    field: string,
    virksomhedsType: "Virksomhed" | "Landbrug" | "Vandanlaeg" | "Virksomhedlandbrug",
    filterOnName = false,
    label = "",
    shortLabel?: string
  ) {
    if (label === "") {
      switch (virksomhedsType) {
        case "Virksomhed":
          label = "Virksomheder med specifikke sagsbehandlere";
          break;
        case "Vandanlaeg":
          label = "Vandanlæg med specifikke sagsbehandlere";
          break;
        case "Landbrug":
          label = "Landbrug med specifikke sagsbehandlere";
          break;
        case "Virksomhedlandbrug":
          label = "Sagsbehandlere";
          break;
      }
    }

    super(field, label, shortLabel ?? "Sagsbehandlere", "contains");
    const sessionStore = useSessionStore();
    this.filterOnName = filterOnName;

    this.model = {
      value: []
    };

    this.editorProps = {
      selectProps: {
        multiple: true,
        label: "Sagsbehandlere",
        labelAttribute: "navn",
        "track-by": "brugerId",
        modelAttribute: null,
        options: []
      },
      async mounted() {
        const { data } = await fetchGet("sagsbehandler/list/" + sessionStore.activeKunde.kundeId + "?type=" + virksomhedsType);
        this.selectProps.options = data;
      }
    };
  }

  createUrlString(): string {
    const sagsbehandlereIds = this.model.value
      .map((x) => {
        if (this.filterOnName) {
          return x.navn;
        } else {
          return x.brugerId;
        }
      })
      .join("|");
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${sagsbehandlereIds})`;
  }
}
