import { nextTick, ref } from "vue";

const trackers = ref(new Set<string>());

/** setShouldReset is called from changeTrackerDialog when the user agrees to continue without saving changes. Listeners to shouldReset should cancel unsaved changes. */
const shouldReset = ref(false);

export function useChangeTrackers() {
  function setChanged(id: string) {
    trackers.value.add(id);
  }

  function clearChanges(id?: string) {
    if (id) {
      trackers.value.delete(id);
    } else {
      trackers.value.clear();
    }
  }

  function hasChanges(id?: string): boolean {
    if (id) {
      return trackers.value.has(id);
    } else {
      return trackers.value.size > 0;
    }
  }

  function setShouldReset() {
    shouldReset.value = true;

    nextTick(() => {
      shouldReset.value = false;
    });
  }

  // let promptResolve: any;
  // let promptReject: any;
  //
  // async function prompt(): Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     promptResolve = resolve;
  //     promptReject = reject;
  //   });
  // }
  //
  // async function clearChangesWithPrompt() {
  //   toggleModal(true);
  //
  //   const shouldContinue = await prompt();
  //
  //   toggleModal(false);
  //
  //   return shouldContinue;
  // }

  // function clearChangesWithPrompt(id?: string) {
  //   if (!hasChanges(id)) {
  //     return true;
  //   }
  //
  //   toggleModal(true);
  //   clearChanges(id);
  //
  //   return false;
  // }

  return {
    hasChanges,
    clearChanges,
    setChanged,
    setShouldReset,
    shouldReset
  };
}
