import { Basis } from "@/models/vandanlaeg/basis";
import { Kontaktperson } from "@/models/vandanlaeg/kontaktperson";
import Lokalitet from "@/models/vandanlaeg/lokalitet";
import { Underanlaeg } from "@/models/vandanlaeg/underanlaeg";
import { Tilsyn } from "@/models/vandanlaeg/tilsyn";
import { Tilladelse } from "@/models/vandanlaeg/tilladelse";
import { Indtag } from "@/models/vandanlaeg/indtag";
import { Haandhaevelse } from "@/models/vandanlaeg/haandhaevelse";
import { OppumpedeMaengde } from "./vandanlaeg/oppumpedemaengde";
import { ImporteredeEksporteredeMaengde } from "./vandanlaeg/importeredeeksporteredemaengde";
import { Anlaegspaabud } from "@/models/vandanlaeg/anlaegspaabud";
import { Boringspaabud } from "@/models/vandanlaeg/boringspaabud";
import { Analysefrekvens } from "@/models/vandanlaeg/analysefrekvens";
import { LogItem } from "@/models/virksomhed/logItem";
import { FastMaalestation } from "./vandanlaeg/fastmaalestation";
import { Adresse } from "./adresse";
import { Koordinat } from "./koordinat";
import { Tidslinje } from "@/models/virksomhed/tidslinje";
import { Matrikel } from "./virksomhed/Matrikel";
import { IndtagNiveau } from "./vandanlaeg/indtagniveau";
import { AdHocOpgaveItem } from "./virksomhed/adHocOpgaveItem";

export default class Vandanlaeg {
  id: string = "";
  basis: Basis = new Basis();
  kontaktpersonListe: Kontaktperson[] = [];
  adresseId: string = "";
  adresse: Adresse = new Adresse();
  underanlaeg: Underanlaeg[] = [];
  wkt: string = "";
  tilsyn: Tilsyn[] = [];
  tilladelser: Tilladelse[] = [];
  haandhaevelse: Haandhaevelse[] = [];
  oppumpedemaengde: OppumpedeMaengde[] = [];
  indtagniveauer: IndtagNiveau[] = [];
  indtagNiveauerSenestValgteIndvindingsMetodeId?: string;
  anlaegNiveauerSenestValgteIndvindingsMetodeId?: string;
  indtagNiveauerSenestValgteOmregningsfaktor?: number;
  anlaegNiveauerSenestValgteOmregningsfaktor?: number;
  boringer: Indtag[] = [];
  logs: LogItem[] = [];
  adHocOpgaver: AdHocOpgaveItem[] = [];
  tidslinje: Tidslinje = new Tidslinje();
  importeredeeksporteredemaengde: ImporteredeEksporteredeMaengde[] = [];
  fastmaalestationer: FastMaalestation[] = [];
  anlaegspaabud: Anlaegspaabud[] = [];
  boringspaabud: Boringspaabud[] = [];
  analysefrekvenser: Analysefrekvens[] = [];
  koordinat: Koordinat = new Koordinat();
  matrikler: Matrikel[] = [];
  slettet: boolean = false;
  lokalitet: Lokalitet = new Lokalitet();
}
