import { Lookup } from "@/models/lookup";
import { VirksomhedsTypeEnum } from "@/models/virksomhed/virksomhedsTypeEnum";
import VirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VirksomhedsTypeFilter";

export default class VirksomhedsTypeVirksomhedLandbrugFilter extends VirksomhedsTypeFilter {
  createLookups() {
    const virksomed = new Lookup();
    virksomed.id = VirksomhedsTypeEnum.VIRKSOMHED.toString();
    virksomed.kode = VirksomhedsTypeEnum.VIRKSOMHED.toString();
    virksomed.navn = "Virksomhed";
    virksomed.beskrivelse = "Virksomhed";

    const landbrug = new Lookup();
    landbrug.id = VirksomhedsTypeEnum.LANDBRUG.toString();
    landbrug.kode = VirksomhedsTypeEnum.LANDBRUG.toString();
    landbrug.navn = "Landbrug";
    landbrug.beskrivelse = "Landbrug";

    const lookups = [];

    lookups.push(virksomed);
    lookups.push(landbrug);

    return lookups;
  }
}
