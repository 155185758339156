import { defineStore } from "pinia";
import { VandanlaegState } from "@/stores/statetypes";
import { HttpResponse } from "@/utils/networking/interfaces";
import Vandanlaeg from "@/models/vandanlaeg";
import { BoringTilladelse } from "@/models/vandanlaeg/boringtilladelse";
import { Tilsyn } from "@/models/vandanlaeg/tilsyn";
import MwFile from "@/models/virksomhed/mwfile";
import { Forsyning, OppumpedeMaengde } from "@/models/vandanlaeg/oppumpedemaengde";
import { CrudEnums } from "@/models/crudEnums";
import { IndtagNiveau } from "@/models/vandanlaeg/indtagniveau";
import { ImporteredeEksporteredeMaengde } from "@/models/vandanlaeg/importeredeeksporteredemaengde";
import { Indtag } from "@/models/vandanlaeg/indtag";
import { useVandanlaegLookupStore } from "@/stores/vandanlaeg-lookup";
import { Pejling } from "@/models/vandanlaeg/pejling";
import { Tilladelse } from "@/models/vandanlaeg/tilladelse";
import { Analysefrekvens } from "@/models/vandanlaeg/analysefrekvens";
import { DiademStof } from "@/models/vandanlaeg/diademstof";
import { Anlaegspaabud } from "@/models/vandanlaeg/anlaegspaabud";
import { Dispensation } from "@/models/vandanlaeg/dispensation";
import { Boringspaabud } from "@/models/vandanlaeg/boringspaabud";
import { Basis } from "@/models/vandanlaeg/basis";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "@/utils/networking/httpclient";
import UrlBuilder from "@/utils/networking/urlBuilder";
import { formatCoordinate } from "@/utils/formatting";
import { NyesteGaeldendeTilladelse } from "@/models/vandanlaeg/NyesteGaeldendeTilladelse";

export const useVandanlaegStore = defineStore("vandanlaeg", {
  state: (): VandanlaegState => {
    return {
      currentVandanlaeg: new Vandanlaeg()
    };
  },
  actions: {
    async fetchVandanlaeg(virksomhedId: string): Promise<HttpResponse> {
      const response = await fetchGet("Vandanlaeg/" + virksomhedId);

      const vandanlaeg: Vandanlaeg = Object.assign(new Vandanlaeg(), response.data);

      vandanlaeg.koordinat = formatCoordinate(vandanlaeg.koordinat);
      this.currentVandanlaeg = vandanlaeg;

      return response;
    },

    async fetchBoringsTilladelser(tilladelseId: string): Promise<BoringTilladelse[]> {
      const response = await fetchGet(`VandanlaegTilladelse/boringstilladelser/${tilladelseId}`);

      return response.data as BoringTilladelse[];
    },

    //Vandanlæg tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn *** tilsyn ***

    async createTilsyn(tilsyn: Tilsyn): Promise<HttpResponse> {
      return await fetchPost("VandanlaegTilsyn", tilsyn);
    },

    async deleteTilsyn(tilsynId: string): Promise<HttpResponse> {
      return await fetchDelete("VandanlaegTilsyn/" + tilsynId);
    },

    async updateTilsyn(tilsyn: Tilsyn): Promise<HttpResponse> {
      return await fetchPut("VandanlaegTilsyn/" + tilsyn.id, tilsyn);
    },

    //Kunne måske komme i en separat actions.files.ts - ligesom i virksomhed? - senere
    async fetchTilsynFiler(id: string): Promise<MwFile[]> {
      if (!id || id.length < 1) {
        console.error("Cannot fetch tilsyn files. The provided tilsyn ID is invalid. Given value: " + id);
        return Promise.reject();
      }

      const response = await fetchGet(`fil/hentFilerPaaVandanlaegTilsyn/${id}`);

      if (response.status < 200 || response.status > 299 || response.data.error) {
        if (response.data.error) {
          return Promise.reject(response.data.error);
        }

        return Promise.reject("Failed to fetch files for tilsyn with ID: " + id);
      }

      return response.data as MwFile[];
    },
    //Oppumpede Mængder *** Oppumpede Mængder Oppumpede Mængder Oppumpede Mængder

    async createOppumpedeMaengde(maengde: OppumpedeMaengde): Promise<HttpResponse> {
      return await fetchPost("OppumpedeVandmaengder", maengde);
    },

    async fetchLedningstab(oppumpedeMaengdeId: string): Promise<HttpResponse> {
      return await fetchGet("OppumpedeVandmaengder/ledningstab/" + oppumpedeMaengdeId);
    },



    async fetchSenestIndvindingsInfoAnlaeg() {
      //gets latest indvindingsmetode and omregningsfaktor for vandanlæg
      const response = await fetchGet("OppumpedeVandmaengder/SenesteOppumpedeMaengdeInfo?virksomhedId=" + this.currentVandanlaeg.id);
      if (response.status && this.currentVandanlaeg) {
        this.currentVandanlaeg.anlaegNiveauerSenestValgteIndvindingsMetodeId = response.data.jupiterIndvindingsmetodeId;
        this.currentVandanlaeg.anlaegNiveauerSenestValgteOmregningsfaktor = response.data.omregningsfaktor;
      }
    },

    async deleteOppumpedeMaengde(maengdeId: string): Promise<HttpResponse> {
      return await fetchDelete("OppumpedeVandmaengder/" + maengdeId);
    },

    /*
        async UPDATE_OPPUMPEDEMAENGDE({ }: VandanlaegContext, maengde: OppumpedeMaengde): Promise<HttpResponse> {
          const { status: status, data: data } = await fetchPut('OppumpedeVandmaengder/' + maengde.id, maengde);
          return { status, data };
        },
        */

    async updateOppumpedeMaengde(maengde: OppumpedeMaengde): Promise<HttpResponse> {
      const item = Object.assign(new OppumpedeMaengde(), maengde);
      item.forsyning = item.forsyning.filter(x => x.status != undefined && x.status !== CrudEnums.UNCHANGED);
      return await fetchPut("OppumpedeVandmaengder/" + maengde.id, item);
    },

    async fetchEkstraForsyningsfordelingsList(maengdeId: string): Promise<Forsyning[]> {
      const { status: status, data } = await fetchGet("forsyning/list/" + maengdeId);
      return data as Forsyning[];
    },

    //Oppumpede Mængder *** Oppumpede Mængder Oppumpede Mængder Oppumpede Mængder

    async createIndtagniveau(indtagniveau: IndtagNiveau): Promise<HttpResponse> {
      return await fetchPost("OppumpedeVandmaengder/IndtagNiveau", indtagniveau);
    },

    async fetchIndtagniveauer(indtagId: string) {
      //gets indtagsniveauer for indtag
      const { status: status, data } = await fetchGet("OppumpedeVandmaengder/list/IndtagNiveau/" + indtagId);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.indtagniveauer = data;
      }

      //gets latest indvindingsmetode and omregningsfaktor for indtag
      const response = await fetchGet("OppumpedeVandmaengder/IndtagNiveau/SenesteOppumpedeMaengdeInfo?indtagId=" + indtagId);
      if (response.status && this.currentVandanlaeg) {
        this.currentVandanlaeg.indtagNiveauerSenestValgteIndvindingsMetodeId = response.data.jupiterIndvindingsmetodeId;
        this.currentVandanlaeg.indtagNiveauerSenestValgteOmregningsfaktor = response.data.omregningsfaktor;
      }
    },

    async deleteIndtagniveau(indtagniveauId: string): Promise<HttpResponse> {
      return await fetchDelete("OppumpedeVandmaengder/IndtagNiveau/" + indtagniveauId);
    },

    async updateIndtagniveau(indtagniveau: IndtagNiveau): Promise<HttpResponse> {
      return await fetchPut("OppumpedeVandmaengder/IndtagNiveau/" + indtagniveau.id, indtagniveau);
    },

    //Importerede / eksporterede mængder

    async createImporteredeEksporteredeMaengde(maengde: ImporteredeEksporteredeMaengde): Promise<HttpResponse> {
      return await fetchPost("ImportEksportVandmaengder", maengde);
    },

    async fetchImporteredeEksporteredeMaengdeList() {
      const { status: status, data } = await fetchGet("ImportEksportVandmaengder/list/" + this.currentVandanlaeg.id);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.importeredeeksporteredemaengde = data;
      }

      return { status, data } as HttpResponse;
    },

    async deleteImporteredeEksporteredeMaengde(maengdeId: string): Promise<HttpResponse> {
      return await fetchDelete("ImportEksportVandmaengder/" + maengdeId);
    },

    async updateImporteredeEksporteredeMaengde(maengde: ImporteredeEksporteredeMaengde): Promise<HttpResponse> {
      return await fetchPut("ImportEksportVandmaengder/" + maengde.id, maengde);
    },

    //Vandanlæg fortsat ***
    async createBoring(indtag: Indtag[]) {
      const { status: status, data } = await fetchPost(`Boring`, indtag);

      const vandanlaegLookupStore = useVandanlaegLookupStore();
      await vandanlaegLookupStore.fetchBoringListSimple(indtag[0].virksomhedId);
    },

    async updateBoring(indtag: Indtag): Promise<HttpResponse> {
      const indtagTemp = Object.assign({}, indtag);
      indtagTemp.pejlinger = indtag.pejlinger.filter(x => x.status != undefined && x.status !== CrudEnums.UNCHANGED);
      return await fetchPut(`Boring/${indtagTemp.id}`, indtagTemp);
    },

    async fetchBoringer() {
      const { status: status, data } = await fetchGet(`Boring/list/${this.currentVandanlaeg.id}`);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.boringer = data;
      }

      return { status, data } as HttpResponse;
    },

    async deleteBoring(id: string): Promise<HttpResponse> {
      const vandanlaegStore = useVandanlaegStore();
      const virksomhedId = vandanlaegStore.currentVandanlaeg.id;
      return await fetchDelete(`Boring/${id}?virksomhedId=${virksomhedId}`);
    },

    async fetchPejlinger(indtagId: string): Promise<Pejling[]> {
      const urlBuilder = new UrlBuilder(`Pejling/list?AnonymId=${indtagId}`);
      urlBuilder.addParameter("start", 0);
      urlBuilder.addParameter("take", 10000);
      const response = await fetchGet(urlBuilder.generateUrlWithParams());

      return response.data as Pejling[];
    },

    async fetchTilladelser() {
      const { status: status, data } = await fetchGet("VandanlaegTilladelse/list/" + this.currentVandanlaeg.id);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.tilladelser = data;
      }

      return { status, data } as HttpResponse;
    },

    async fetchLatestVandanlaegtilladelseByYear(start: moment.Moment, slut: moment.Moment) {
      const response = await fetchGet(
        `VandanlaegTilladelse/LatestVandanlaegtilladelseByYear/${this.currentVandanlaeg.id}?start=${start.format(
          "YYYY-MM-DD"
        )}&slut=${slut.format("YYYY-MM-DD")}`
      );

      return response.data as NyesteGaeldendeTilladelse;
    },

    async createTilladelse(tilladelse: Tilladelse): Promise<HttpResponse> {
      tilladelse.jupiterTilladelseFormaalId = "20200319-1407-0741-0001-968440585108"; // feltet skal fjernes efter d.18-06-2020 og når det ikke længere er påkrævet af backenden
      return await fetchPost(`VandanlaegTilladelse`, tilladelse);
    },

    async updateTilladelse(tilladelse: Tilladelse): Promise<HttpResponse> {
      tilladelse.jupiterTilladelseFormaalId = "20200319-1407-0741-0001-968440585108"; // feltet skal fjernes efter d.18-06-2020 og når det ikke længere er påkrævet af backenden
      return await fetchPut(`VandanlaegTilladelse/${tilladelse.id}`, tilladelse);
    },

    async deleteTilladelse(id: string): Promise<HttpResponse> {
      return await fetchDelete(`VandanlaegTilladelse/${id}`);
    },

    async fetchAnalysefrekvenser() {
      const { status: status, data } = await fetchGet("DiademAnalysefrekvens/list/" + this.currentVandanlaeg.id);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.analysefrekvenser = data;
      }

      return { status, data } as HttpResponse;
    },

    async createAnalysefrekvens(analysefrekvens: Analysefrekvens): Promise<HttpResponse> {
      return await fetchPost(`DiademAnalysefrekvens`, analysefrekvens);
    },

    async updateAnalysefrekvens(analysefrekvens: Analysefrekvens): Promise<HttpResponse> {
      return await fetchPut(`DiademAnalysefrekvens/${analysefrekvens.id}`, analysefrekvens);
    },

    async deleteAnalysefrekvens(id: string): Promise<HttpResponse> {
      return await fetchDelete(`DiademAnalysefrekvens/${id}`);
    },

    async fetchDiademStoffer(analysefrekvensId: string): Promise<DiademStof[]> {
      const response = await fetchGet(`DiademStof/list/${analysefrekvensId}`);
      return response.data as DiademStof[];
    },

    async fetchAnlaegspaabud() {
      const { status: status, data } = await fetchGet("DiademAnlaegspaabud/list/" + this.currentVandanlaeg.id);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.anlaegspaabud = data;
      }

      return { status, data } as HttpResponse;
    },

    async createAnlaegspaabud(anlaegspaabud: Anlaegspaabud): Promise<HttpResponse> {
      return await fetchPost(`DiademAnlaegspaabud`, anlaegspaabud);
    },

    async updateAnlaegspaabud(anlaegspaabud: Anlaegspaabud): Promise<HttpResponse> {
      return await fetchPut(`DiademAnlaegspaabud/${anlaegspaabud.id}`, anlaegspaabud);
    },

    async deleteAnlaegspaabud(id: string): Promise<HttpResponse> {
      return await fetchDelete(`DiademAnlaegspaabud/${id}`);
    },

    async fetchAnlaegspaabudDispensationer(anlaegspaabudId: string): Promise<Dispensation[]> {
      const response = await fetchGet(`DiademAnlaegspaabudDispensation/list/${anlaegspaabudId}`);
      return response.data as Dispensation[];
    },

    async fetchBoringspaabud() {
      const { status: status, data } = await fetchGet("DiademBoringspaabud/list/" + this.currentVandanlaeg.id);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.boringspaabud = data;
      }

      return { status, data } as HttpResponse;
    },

    async createBoringspaabud(boringspaabud: Boringspaabud): Promise<HttpResponse> {
      return await fetchPost(`DiademBoringspaabud`, boringspaabud);
    },

    async updateBoringspaabud(boringspaabud: Boringspaabud): Promise<HttpResponse> {
      return await fetchPut(`DiademBoringspaabud/${boringspaabud.id}`, boringspaabud);
    },

    async deleteBoringspaabud(id: string): Promise<HttpResponse> {
      return await fetchDelete(`DiademBoringspaabud/${id}`);
    },

    async fetchBoringspaabudDispensationer(boringspaabudId: string): Promise<Dispensation[]> {
      const response = await fetchGet(`DiademBoringspaabudDispensation/list/${boringspaabudId}`);
      return response.data as Dispensation[];
    },

    async oppumpedeMaengderBeregnedeFelter(maengde: OppumpedeMaengde): Promise<HttpResponse> {
      return await fetchPost(`OppumpedeVandmaengder/OppumpedeMaengderBeregnedeFelter`, maengde);
    },
    //UPDATE BASIS
    async updateVandanlaegBasis(vandanlaeg: Vandanlaeg): Promise<HttpResponse> {
      const response = await fetchPut("vandanlaeg/" + vandanlaeg.id + "/basis", vandanlaeg.basis);

      if (this.currentVandanlaeg) {
        this.currentVandanlaeg.basis = Object.assign(new Basis(), response.data.basis);
      }

      return response;
    },

    async fetchSumForsyningsarter(forsyningsarter: Forsyning[]) {
      const { status, data } = await fetchPost("vandanlaeg/sumForsyningsarter", forsyningsarter);
      return { status, data };
    }
  },
  getters: {}
});
