export default class UrlBuilder {
  private url: string;
  private params: UrlParameter[] = [];

  constructor(url: string) {
    this.url = url;
  }

  addParameter(key: string, value: any) {
    if (value || value === 0) {
      this.params.push({ key, value });
    }
  }

  addParameters(obj: object) {
    Object.entries(obj).forEach(([key, value]) => {
      this.addParameter(key, value);
    });
  }

  addParameterWithArrayValue(key: string, values: string[] | number[] | undefined) {
    if (!values) {
      return;
    }

    for (const value of values) {
      if (value || value === 0) {
        this.addParameter(key, value);
      }
    }
  }

  setUrl(url: string) {
    this.url = url;
  }

  getUrl(): string {
    return this.url;
  }

  generateUrlWithParams(): string {
    const baseUrl = import.meta.env.VITE_MW_API_URL;
    const url = new URL(this.url, baseUrl);

    this.params.forEach((parameter, index) => {
      // const param = index === 0 ? '?' : '&';
      // this.url += param + encodeURIComponent(parameter.key) + '=' + encodeURIComponent(parameter.value);
      url.searchParams.append(parameter.key, parameter.value);
    });
    return url.href;
  }
}

class UrlParameter {
  key: string = "";
  value: string = "";
}
