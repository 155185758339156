<template>
  <li class="nav-item dropdown" v-if="availableCustomers && availableCustomers.length > 1">
    <a class="nav-link nav-start dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <MwIcon icon="users" />{{ sessionStore.activeKunde.kundeNavn }}
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <div style="max-height: 400px; overflow: auto">
        <button
          type="button"
          class="dropdown-item"
          v-for="(kunde, kundeIndex) in availableCustomers"
          :key="kundeIndex"
          :class="[kunde.kundeId === sessionStore.activeKunde.kundeId ? 'dark-gray-superlight' : '']"
          @click="sessionStore.updateActivePrincipal(kunde.kundeId)"
        >
          <template v-if="kunde.kundeNavn">{{ kunde.kundeNavn }}</template>
          <template v-else>{{ kunde.kundeId }}</template>
        </button>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import { Kunde } from "@/models/bruger";
import { useSessionStore } from "@/stores/session";
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import { Ref, onMounted, ref } from "vue";

const sessionStore = useSessionStore();
const availableCustomers: Ref<Kunde[]> = ref([]);

onMounted(async () => {
  //Fetch and sort by name
  availableCustomers.value = (await sessionStore.hentTilgaengeligeKunder()).sort((a, b) => a.kundeNavn.localeCompare(b.kundeNavn));
});
</script>
<style scoped></style>
