import { DmaStatusEnums } from "@/models/dmaStatusEnums";
import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";

export default class DmaStatusIdFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "equals");

    this.editorProps = {
      selectProps: {
        label: "Dma Status",
        labelAttribute: "navn",
        autosSort: false,
        options: [
          { navn: "Aktiv", id: DmaStatusEnums.AKTIV, kode: "1", beskrivelse: "DMA Aktiv status" },
          { navn: "Inaktiv", id: DmaStatusEnums.INAKTIV, kode: "2", beskrivelse: "DMA Inaktiv status" }
        ]
      }
    };

    this.model = {
      value: DmaStatusEnums.AKTIV
    };
  }

  createUrlString(): string {
    return `${this.serverModelPropertyName}:${this.serverFilterKey}(${this.model.value})`;
  }
  createDisplayValue() {
    return `${this.shortLabel}: ${this.model.value === DmaStatusEnums.AKTIV ? "Aktiv" : "Inaktiv"}`;
  }
}
