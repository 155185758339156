import { formatBooleanGaeldendeTilladelse, formatDate } from "@/filters/mwTableFormatters";
import { IMWReportColumn } from "./IMWReportColumn";
import { ReportType } from "./ReportType";
import { IReportModel } from "./IReportModel";
import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";

import { nameof } from "@/utils/utils";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import VandTilladelseAktivFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandTilladelseAktivFilter";

export class BoringTilladelseReportModel implements IReportModel<BoringTilladelseReportModel> {
  aarstal?: number;
  maaned?: number;
  slutAarstal?: number;
  slutMaaned?: number;
  startdato?: Date;
  slutdato?: Date;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  gaeldendeTilladelse?: boolean;
  virksomhedsnavn?: string;
  virksomhedsAdresse?: string;
  cvrnr?: string;
  pnr?: string;
  primaerVirksomhedstypeId?: string;
  primaerVirksomhedstype?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegsnr?: string;
  anlaegsartId?: string;
  anlaegsart?: string;
  jupiterAktivStatusId?: string;
  jupiterAktivStatus?: boolean;
  indvindingsformaal?: string;
  dgUnr?: string;
  loebenr?: number;
  tilladelsestypeId?: string;
  tilladelsestype?: string;
  tilladelseJupiterId?: string;
  vandmaengdeM3Aar?: number;
  vandmaengdeM3Time?: string;
  maksimalSaenkning?: number;
  pejlefrekvens?: number;
  sumVandmaengdeM3Aar?: number;
  antal?: number;

  get columnModel(): IMWReportColumn<BoringTilladelseReportModel>[] {
    return [
      {
        id: "aarstal",
        label: "Start år",
        show: true
      },
      {
        id: "maaned",
        label: "Start måned",
        show: true
      },
      {
        id: "slutAarstal",
        label: "Slut år",
        show: true
      },
      {
        id: "slutMaaned",
        label: "Slut måned",
        show: true
      },
      {
        id: "startdato",
        label: "Startdato",
        show: true,
        formatter: formatDate
      },
      {
        id: "slutdato",
        label: "Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "gaeldendeTilladelse",
        label: "Gældende tilladelse for boring",
        show: false,
        formatter: formatBooleanGaeldendeTilladelse
      },
      {
        id: "anlaegStartdato",
        label: "Anlæg Startdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegSlutdato",
        label: "Anlæg Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "virksomhedsnavn",
        label: "Anlægsnavn",
        show: false
      },
      {
        id: "virksomhedsAdresse",
        label: "Anlægsadresse",
        show: false
      },
      {
        id: "cvrnr",
        label: "CVR-nummer",
        show: false
      },
      {
        id: "pnr",
        label: "P-nummer",
        show: false
      },
      {
        id: "primaerVirksomhedstype",
        label: "Primær virksomhedstype",
        show: false
      },
      {
        id: "oevrigeVirksomhedstyper",
        label: "Øvrige virksomhedstyper",
        show: false,
        sort: false
      },
      {
        id: "anlaegsnr",
        label: "Anlægsnummer",
        show: false
      },
      {
        id: "anlaegsart",
        label: "Anlægsart",
        show: false
      },
      {
        id: "indvindingsformaal",
        label: "Indvindingsformål",
        show: false
      },

      {
        id: "jupiterAktivStatus",
        label: "Anlæg aktivstatus",
        show: false
      },
      {
        id: "dgUnr",
        label: "DGU nummer",
        show: false,
        sort: false
      },
      {
        id: "loebenr",
        label: "Løbenummer",
        show: false
      },

      {
        id: "tilladelsestype",
        label: "Tilladelsestype",
        show: true
      },
      {
        id: "tilladelseJupiterId",
        label: "Jupiter ID",
        show: true
      },

      {
        id: "vandmaengdeM3Aar",
        label: "Tilladt mængde m3/år for boring",
        show: false
      },
      {
        id: "vandmaengdeM3Time",
        label: "Tilladt mængde m3/time for boring",
        show: false
      },

      {
        id: "maksimalSaenkning",
        label: "Maksimal sænkning",
        show: false
      },
      {
        id: "pejlefrekvens",
        label: "Pejlefrekvens",
        show: false
      },
      {
        id: "sumVandmaengdeM3Aar",
        label: "Sum tilladt mængde m3/år for boring",
        show: false
      },
      {
        id: "antal",
        label: "Antal",
        show: false
      }
    ];
  }

  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<BoringTilladelseReportModel>("startdato"), "Startdato", "Startdato"),
      new DateRangeFilter(nameof<BoringTilladelseReportModel>("slutdato"), "Slutdato", "Slutdato"),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<BoringTilladelseReportModel>("primaerVirksomhedstypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new JupiteranlaegsartFilter(nameof<BoringTilladelseReportModel>("anlaegsartId"), "Anlægsart", "Anlægsart"),
      new VandTilladelseAktivFilter(
        nameof<BoringTilladelseReportModel>("gaeldendeTilladelse"),
        "Gældende tilladelse for boring",
        "Gældende tilladelse for boring"
      ),
      new JupiterAktivStatusFilter(nameof<BoringTilladelseReportModel>("jupiterAktivStatusId"), "Aktiv status", "Aktiv status"),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper")
    ];
  }
  get reportType(): ReportType {
    return ReportType.BoringTilladelse;
  }
}
