import { defineStore } from "pinia";
import { FakturaState } from "@/stores/statetypes";
import { HttpResponse } from "@/utils/networking/interfaces";
import { fetchGet, fetchPost } from "@/utils/networking/httpclient";
import UrlBuilder from "@/utils/networking/urlBuilder";
import { useSessionStore } from "@/stores/session";

export const useFakturaStore = defineStore("faktura", {
  state: (): FakturaState => {
    return {
      fakturaoversigt: [],
      fakturafejlede: [],
      oeir: false
    };
  },
  actions: {
    async fetchOir(): Promise<HttpResponse> {
      const sessionStore = useSessionStore();
      const kundeId = sessionStore.activeKunde.kundeId;
      const response = await fetchGet(`Brugerbetaling/BrugOIR?kundeId=${kundeId}`);
      this.oeir = response.data;

      return response;
    },
    async krediterFaktura(faktura: any): Promise<HttpResponse> {
      return await fetchGet("Brugerbetaling/KrediterBatch?batchId=" + faktura.id);
    },
    async annullerFaktura(faktura: any): Promise<HttpResponse> {
      return await fetchGet("Brugerbetaling/AnnullerFakturering?afregningBatchId=" + faktura.id);
    }
  },
  getters: {
    isOeir(): boolean {
      return this.oeir;
    }
  }
});
