<template>
  <transition name="modal-reveal">
    <div class="modal-background-mask" @keydown.escape="onCloseClicked()" ref="focusMeRef" tabindex="0">
      <div class="modal modal-visible" tabindex="-1" role="dialog">
        <div :class="[customCssClasses, computedSize]" class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button @click="onCloseClicked" class="close close-white" data-dismiss="modal" data-keyboard="true" type="button">
                <MwIcon icon="times-circle" />
              </button>
              <h4 class="modal-title">{{ header }}</h4>
            </div>
            <div class="modal-body gray-superlight p-0" :style="{ overflow: overflow, 'max-height': maxHeight, 'min-height': minHeight }">
              <slot></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <MwButton color="primary" @click="onCloseClicked">Ok</MwButton>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, ref, withDefaults } from "vue";
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import MwButton from "@/components/mwUtils/MwButton.vue";

interface Props {
  header?: string;
  maxHeight?: string;
  minHeight?: string;
  overflow?: string;
  classes?: string;
  size?: "small" | "medium" | "large" | "x-large";
}

const props = withDefaults(defineProps<Props>(), {
  header: "",
  maxHeight: "600px",
  minHeight: "",
  overflow: "auto",
  classes: "",
  size: "medium"
});

const emit = defineEmits<{
  (eventName: "close", value: boolean): void;
}>();

const focusMeRef = ref<HTMLDivElement | null>(null);

const computedSize = computed(() => {
  switch (props.size) {
    case "small":
      return "modal-sm";
    case "medium":
      return "";
    case "large":
      return "modal-lg";
    case "x-large":
      return "modal-xl";
  }

  return "";
});

onMounted(() => {
  //Dette er nødvendigt sammen med tabindex for at kunne lukke modalen med escape uden at sætte et event i roden af Miljøweb
  //Det er desuden vigtigt at bruge v-if og ikke v-show så mounted bliver kaldt
  if (focusMeRef.value) {
    //@ts-ignore
    focusMeRef.value.focus();
  }
});

const customCssClasses = computed(() => {
  const classes = props.classes.split("s+");
  const entries = new Map<string, boolean>();

  for (const cls of classes) {
    entries.set(cls, true);
  }

  return Object.fromEntries(entries);
});

function onCloseClicked() {
  emit("close", true);
}
</script>

<style scoped>
div:focus {
  outline: none;
}

.modal-background-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-visible {
  display: block;
}

.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  color: #fff;
  margin: 0;
}

.modal-header .close {
  top: -0.1;
}

.modal-reveal-enter {
  opacity: 0;
}

.modal-reveal-leave-active {
  opacity: 0;
}

.modal-reveal-enter .modal-container,
.modal-reveal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-alert {
  background-color: #821307;
  color: white;
}
</style>
