<template>
  <MwModal v-if="isModalVisible" @close="onCancelClicked" header="Ændringer i formularen">
    <fieldset style="margin-bottom: 0">
      <div class="d-flex">
        <div class="mr-5 d-flex align-items-center"><span class="fad fa-exclamation-triangle fa-2x"></span></div>
        <div class="flex-grow-1">
          <h5>Der er foretaget ændringer i formularen som ikke er gemt.<br />Gå videre uden at gemme?</h5>
        </div>
      </div>
    </fieldset>
    <template v-slot:footer>
      <MwButton color="secondary" @click="onCancelClicked">Bliv på formularen</MwButton>
      <MwButton color="primary" @click="onConfirmClicked">Gå videre uden at gemme</MwButton>
    </template>
  </MwModal>
</template>
<script lang="ts" setup>
import MwModal from "./mwUtils/mwModals/MwModal.vue";
import MwButton from "./mwUtils/MwButton.vue";
import { useChangeTrackers } from "./mwUtils/changeTracker/useChangeTrackers";
import { useRouter } from "vue-router";
import { clearChangesWithPrompt, isModalVisible, promptResolve } from "@/components/mwUtils/changeTracker/ChangeTrackerDialog";

const { hasChanges, clearChanges } = useChangeTrackers();

function onConfirmClicked() {
  promptResolve.value(true);
}

function onCancelClicked() {
  promptResolve.value(false);
}

const router = useRouter();
router.beforeEach(onRouteChangedGuard);

async function onRouteChangedGuard() {
  const shouldContinue = await clearChangesWithPrompt();

  if (!shouldContinue) {
    return false;
  }
}
</script>
