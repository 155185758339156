import SelectFilter from "@/components/mwUtils/mwTable/Filters/SelectFilter";
import { fetchLookup, nameof } from "@/utils/utils";
import { useLookupStore } from "@/stores/lookup";
import { Lookup } from "@/models/lookup";

export default class VirksomhedsgruppeFilter extends SelectFilter {
  constructor(field: string, title: string, shortLabel: string) {
    super(field, title, shortLabel, "contains");

    this.modelLabelKey = nameof<Lookup>("beskrivelse");

    this.editorProps = {
      selectProps: {
        modelAttribute: null,
        multiple: true,
        label: "Virksomhedsgruppe",
        labelAttribute: "beskrivelse"
      },
      async mounted() {
        const lookupStore = useLookupStore();
        await fetchLookup("DmaLookup/virksomhedsgrupper", "virksomhedsgrupper");
        this.selectProps.options = lookupStore.virksomhedsgrupper;
      }
    };
  }
}
