<template>
  <i :class="computedClass" v-bind="$attrs" aria-hidden="true" data-cy="icon"></i>
</template>

<script lang="ts" setup>
import { computed, defineProps, withDefaults } from "vue";

interface Props {
  type?: "fa" | "far" | "fas" | "fal" | "fad" | "regular" | "solid" | "light" | "duotone" | "pro";
  prefix?: string;
  icon: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "fa",
  prefix: "fa"
});

const resolvedType = computed(() => {
  switch (props.type.toLowerCase()) {
    case "regular":
      return "far";
    case "solid":
      return "fas";
    case "light":
      return "fal";
    case "pro":
    case "duotone":
      return "fad";
    default:
      return props.type;
  }
});

const computedClass = computed(() => {
  return `${resolvedType.value} ${props.prefix}-${props.icon}`;
});
</script>
