import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import { ReportType } from "./ReportType";
import { formatDate } from "@/filters/mwTableFormatters";
import { nameof } from "@/utils/utils";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import JupiterKontaktpersonTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterKontaktpersonTypeFilter";
import JupiterKontaktPersontypeAktivFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterKontaktPersontypeAktivFilter";

export class VandanlaegKontaktpersonerReportModel implements IReportModel<VandanlaegKontaktpersonerReportModel> {
  navn?: string;
  email?: string;
  telefon?: string;
  mobil?: string;
  adresse?: string;
  postnr?: number;
  jupiterId?: number;
  startDato?: Date;
  slutDato?: Date;
  kontaktTypeAktiv?: boolean;
  kontaktType?: string;
  kontaktTypeId?: string;
  anlaegsnavn?: string;
  anlaegsadresse?: string;
  antalRoller?: number;

  get columnModel(): IMWReportColumn<VandanlaegKontaktpersonerReportModel>[] {
    return [
      {
        id: "navn",
        label: "Kontaktperson Navn",
        show: true
      },
      {
        id: "email",
        label: "Email",
        show: false
      },
      {
        id: "telefon",
        label: "Telefon",
        show: false
      },
      {
        id: "mobil",
        label: "Mobil",
        show: false
      },
      {
        id: "adresse",
        label: "Kontakt Adresse",
        show: false
      },
      {
        id: "postnr",
        label: "Kontakt Postnr",
        show: false
      },
      {
        id: "jupiterId",
        label: "JupiterId",
        show: false
      },
      {
        id: "kontaktType",
        label: "Kontakttype",
        show: false
      },
      {
        id: "startDato",
        label: "Startdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "slutDato",
        label: "Slutdato",
        show: false,
        formatter: formatDate
      },
      {
        id: "anlaegsnavn",
        label: "Anlægsnavn",
        show: true
      },
      {
        id: "anlaegsadresse",
        label: "Anlægsadresse",
        show: true
      },
      {
        id: "antalRoller",
        label: "Antal Roller",
        show: true
      }
    ];
  }
  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }

  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<VandanlaegKontaktpersonerReportModel>("startDato"), "Rolle startdato", "Rolle startdato"),
      new JupiterKontaktpersonTypeFilter(nameof<VandanlaegKontaktpersonerReportModel>("kontaktTypeId"), "Kontakttype", "Kontakttype"),
      new JupiterKontaktPersontypeAktivFilter(
        nameof<VandanlaegKontaktpersonerReportModel>("kontaktTypeAktiv"),
        "Kontakttype aktiv",
        "Kontakttype aktiv"
      )
    ];
  }

  get reportType(): ReportType {
    return ReportType.VandanlaegKontaktpersoner;
  }
}
