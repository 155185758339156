<template>
  <MwModal @close="onModalClosed" header="Indstillinger">
    <fieldset style="margin-bottom: 0">
      <table>
        <tr>
          <td><b>BRUGERNAVN</b></td>
          <td><MwInput :modelValue="sessionStore.bruger.userName" disabled="true"></MwInput></td>
        </tr>
        <tr>
          <td style="vertical-align: top"><b>RETTIGHEDER</b></td>
          <td>
            <ul style="list-style: none; padding-left: 0">
              <li v-for="resource in sessionStore.bruger.roller" :key="resource">{{ resource }}</li>
            </ul>
          </td>
        </tr>
      </table>

      <form>
        <MwCheckbox
          :modelValuev-model="sessionStore.brugerSettings.visFavoritAdresser"
          @update:modelValue="changeVisFavoritAdresser"
          label="Vis adresse i favoritter"
        ></MwCheckbox>
      </form>

      <template v-if="isAdministrator">
        <hr />

        <MwCollapseBox titel="Avancerede indstillinger" :collapsed="true">
          <table>
            <tr>
              <td><b>BRUGER ID</b></td>
              <td><MwInput :modelValue="sessionStore.bruger.brugerId" disabled="true"></MwInput></td>
            </tr>
          </table>
        </MwCollapseBox>
        <MwCollapseBox titel="Brugeroplysninger (rå data)" :collapsed="true">
          <pre>{{ sessionStore.bruger }}</pre>
        </MwCollapseBox>
        <MwCollapseBox titel="Brugerindstillinger (rå data)" :collapsed="true">
          <pre>{{ sessionStore.brugerSettings }}</pre>
        </MwCollapseBox>
      </template>
      <hr />
      <MwButton color="primary" class="mr-3" @click="nulstilIndstillinger()">Nulstil indstillinger</MwButton>
      <MwButton color="primary" @click="nulstilFavoritter()">Nulstil favoritter</MwButton>
    </fieldset>
  </MwModal>
</template>

<script lang="ts" setup>
import MwModal from "@/components/mwUtils/mwModals/MwModal.vue";
import MwCollapseBox from "@/components/mwUtils/MwCollapseBox.vue";
import MwInput from "@/components/mwUtils/mwInputs/MwInput.vue";
import MwCheckbox from "./mwUtils/mwInputs/MwCheckbox.vue";
import MwButton from "./mwUtils/MwButton.vue";
import { useSessionStore } from "@/stores/session";
import { ref } from "vue";
import { useMainStore } from "@/stores/main";
import { storeToRefs } from "pinia";
import { KundeRoleEnums } from "@/models/KundeRoleEnums";

const isModalVisible = ref(false);
const sessionStore = useSessionStore();
const mainStore = useMainStore();
const isAdministrator = ref(sessionStore.hasRights(KundeRoleEnums.SYSTEM_ADMIN));

const { userSettingsModalVisible } = storeToRefs(mainStore);

function changeVisFavoritAdresser(val: boolean) {
  sessionStore.brugerSettings.visFavoritAdresser = val;
  sessionStore.toggleVisFavoritAdresser(val);
}

async function nulstilIndstillinger() {
  const check = confirm("Er du sikker?");
  if (check) {
    await sessionStore.resetBrugerSettings();
  }
}

async function nulstilFavoritter() {
  const check = confirm("Er du sikker?");
  if (check) {
    await sessionStore.resetFavorites();
  }
}

function onModalClosed() {
  userSettingsModalVisible.value = false;
}
</script>

<style scoped>
table {
  width: 100%;
}

table td:first-child {
  vertical-align: middle;
}

pre {
  max-height: 250px;
  white-space: pre-wrap;
  overflow: auto;
}
</style>
