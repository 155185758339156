<template>
  <MwModal @close="onForbiddenModalClosed" header="Der er ikke de fornødne rettigheder til denne handling.">
    <fieldset style="margin-bottom: 0">
      <div>
        <p class="h5" v-for="content in modalContent">
          {{ content }}
        </p>
      </div>
    </fieldset>
  </MwModal>
</template>

<script setup lang="ts">
import MwModal from "@/components/mwUtils/mwModals/MwModal.vue";
import { useMainStore } from "@/stores/main";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const mainStore = useMainStore();
const { forbiddenError, forbiddenErrorModalVisible } = storeToRefs(mainStore);

const modalContent = computed(() => {
  let modalContent = forbiddenError.value.forbiddenModalContent.split(", ");
  return modalContent;
});

function onForbiddenModalClosed() {
  forbiddenErrorModalVisible.value = false;
}
</script>

<style scoped></style>
