<template>
  <li class="close-nav-item dropdown">
    <a class="nav-link nav-start position-relative" style="width: 15px" href="#" data-toggle="dropdown">
      <MwIcon icon="bell" />
      <span class="badge rounded-pill bg-success small-badge" v-show="userNotifications.length > 0"> {{ userNotifications.length }} </span>
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <div style="min-width: 300px; overflow: auto">
        <h6 class="dropdown-header">
          <span>Notifikationer</span><a class="refresh-button" @click.stop="fetchNotifications()"><MwIcon icon="refresh"></MwIcon></a>
        </h6>
        <div class="dropdown-divider"></div>
      </div>
      <div style="max-height: 320px; overflow: auto">
        <template v-if="loading">
          <div class="text-center text-muted custom-message"><MwSpinner /></div>
        </template>
        <template v-else>
          <template v-if="userNotifications.length > 0">
            <MwNotification v-for="(notif, notifIndex) in userNotifications" :key="notifIndex" :notification="notif" />
          </template>
          <div v-else class="text-center text-muted custom-message">Ingen nye notifikationer</div>
        </template>
      </div>
    </div>
  </li>
</template>
<script lang="ts" setup>
import MwIcon from "@/components/mwUtils/MwIcon.vue";
import MwNotification from "@/components/MwNotification.vue";
import { Notification } from "@/models/bruger";
import { useSessionStore } from "@/stores/session";
import { Ref, onMounted, ref } from "vue";
import MwSpinner from "@/components/mwUtils/MwSpinner.vue";

const sessionStore = useSessionStore();
const userNotifications: Ref<Notification[]> = ref([]);
const loading: Ref<boolean> = ref(false);

onMounted(fetchNotifications);

async function fetchNotifications() {
  loading.value = true;

  await new Promise(resolve => setTimeout(resolve, 250));
  try {
    userNotifications.value = await sessionStore.hentBrugerNotifikationer();
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
}

.refresh-button {
  cursor: pointer;
}

.dropdown-item {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.position-relative .badge {
  transform: translate(-60%, -50%);
}

.custom-message,
.loading-message {
  padding: 0.5rem 1rem;
}
</style>
