// Note: This is required to ensure any regexes is standards compliant.
'use strict';

/**
 * Attempts to parse the filename provided in a HTTP Content-Disposition header.
 * 
 * @param disposition The contents of the HTTP Content-Disposition header returned when downloading a file.
 * @returns The parsed filename on success or "unknown" on failure.
 */
export function parseFilenameFromContentDisposition(disposition: string): string {
    if (!disposition || disposition.length < 1) {
        console.warn('The HTTP Content-Disposition header provided is undefined, NULL or empty!');
        return 'ukendt-filnavn';
    }

    /*
     * This regex will match strings using the following formats (with optional single or double qoutes around the filename value):
     *  1) attachment; filename=filename.ext;
     *  2) attachment; filename=filename.ext; filename*=UTF-8''filename.ext
     *
     * Explaination:
     * 
     *      filename                # Matches "filename" literal.
     *      \*?                     # Matches * literal zero or one time.
     *      =                       # Matches = literal.
     *      ['"]?                   # Matches either ' or " zero or one time.
     *      (?:UTF-8['"]{0,2})?     # Optionally matches "UTF-8" literal followed by either ' or " zero or 2 times.
     *      ([^;\r\n"']+)           # Captures anything that isn't a semicolon, newline, ' or " 1 or more times.
     *      ['"]?                   # Matches either ' or " zero or one time.
     *      ;?                      # Optionally matches a semicolon.
     * 
     * We're using the following modifiers:
     *  1) "g" to ensure the entire string is checked
     *  2) "u" to add Unicode support
     *  3) "i" to make it case-insensitive.
     */
    const regex = new RegExp(/filename\*?=['"]?(?:UTF-8['"]{0,2})?([^;\r\n"']+)['"]?;?/, 'gui');
    const matches = regex.exec(disposition);

    if (!matches || matches.length < 1) {
        console.warn('Unable to determine filename from content-dispositon HTTP header! Value provided: ' + disposition);
        return 'ukendt-filnavn';
    }

    // If there is 2 matches the second one is a Unicode representation of the filename and should be preferred
    // to preserve the original name of the file.
    return matches.length >= 2 ? matches[1] : matches[0];
}