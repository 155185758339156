import moment from "moment";
import { CrudEnums } from "@/models/crudEnums";

export class OppumpedeMaengde {
  id: string = "";
  startdato: moment.Moment | null = null;
  slutdato: moment.Moment | null = null;
  grundvand: string = "";
  overfladevand: string = "";
  maalerstandStart: string = "";
  maalerstandSlut: string = "";
  jupiterId: string = "";
  jupiterGuid: string = "";
  tidligereMaalerstandSlut: string = "";
  jupiterIndvindingsmetodeId: string = "";
  kontrolleret?: boolean;
  kontrolleretTekst: string = "";
  forsyning: Forsyning[] = [];
  bemaerkning: string = "";
  vandmaalerSubMetode: number;
  omregningsfaktor: string = "";
  filterskylning!: string;
  vandvaerketsEgetForbrug!: string;
  samletLeveretVandmaengde!: string;
  vandvaerketsEgetForbrugBemaerkning: string = "";
  tilladtVandmaengde?: number;
  tilladtVandmaengdeStartSlutDato: string = "";
}

export class Forsyning {
  id: string = "";

  oppumpedeMaengderId: string = ""; //HVILKEN EN AF DISSE 2 ER RIGITIGE????? Tjek med backend!
  oppumpedeVandmaengderId: string = ""; //HVILKEN EN AF DISSE 2 ER RIGITIGE????? Tjek med backend!

  jupiterForsyningsartId: string = "";
  maengde: number = 0;
  modtagerAntal: number = 0;
  individAntal: number = 0;
  jupiterIndvinderEnhedId: string = "";
  bemaerkning: string = "";

  //pt ikke med i ui
  start: moment.Moment = moment();
  slut: moment.Moment = moment();
  jupiterGuid: string = "";
  jupiterId: number = 0;
  jupiterOpdateret: boolean = false;

  status: CrudEnums | undefined = undefined;
}
