<template>
  <div class="pt-4">
    <div class="check" data-cy="mwCheckbox" :class="{ reversed: reversed }">
      <input :id="id" :checked="modelValue" @input="input($event)" type="checkbox" :disabled="disabled" data-cy="mwCheckbox-input" />
      <label :for="id" data-cy="mwCheckbox-label">{{ label }}</label>
    </div>

    <!-- <div class="form-control-feedback" v-if="validationError">{{ validationError }}</div> -->
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { ChangeTrackerSetChangedFunction } from "@/components/mwUtils/changeTracker/ChangeTracker";

interface Props {
  modelValue?: boolean;
  reversed?: boolean;
  label?: string;
  disabled?: boolean;
  relatedEntityId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  reversed: false,
  label: "",
  disabled: false,
  relatedEntityId: ""
});

const emit = defineEmits<{
  (eventName: "update:modelValue", modelValue: boolean): void;
}>();

const id = computed(() => "check-" + props.label + Math.ceil(Math.random() * 1000000));

const setTrackerChanged = inject<ChangeTrackerSetChangedFunction>("setChanged", () => null);

function input(event: any) {
  emit("update:modelValue", event.target.checked);

  if (setTrackerChanged) {
    setTrackerChanged();
  }
}
</script>

<style scoped>
.bottom-align-checkbox {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 25px;
}
.reversed {
  float: right;
  margin-right: 30px;
}
.reversed label {
  padding: 10px 0 0 0px !important;
}
.reversed label:after {
  margin-left: 10px;
  left: auto;
}
.reversed label:before {
  display: none;
}

/**
@TODO Kan muligvis fjernes når vi opgrare sweco-bootstrap eller skifter til vue-sdp
CSS Overskriver sweco-bootstrap 4.x fordi Vue3 laver 'disabled="disabled"' om til 'disabled', hvilket ødelægger css selector.
 */
[disabled] ~ label::after {
  color: #a5a5a8 !important;
  cursor: default;
}
</style>
