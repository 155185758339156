export class Adresse {
  dawaAdresseId: string = '';
  dawaAddresse: any = null;
  vejnavn: string = '';
  husnummer: string = '';
  etage: string = '';
  sideDoer: string = '';
  postnummer: string = '';
  city: string = '';
  fuldeAdresse: string = '';
  x: string = '';
  y: string = '';
  supplerendebynavn: string = '';
}
