import IMwTableFilter from "@/components/mwUtils/mwTable/Filters/IMwTableFilter";
import { IMWReportColumn } from "./IMWReportColumn";
import { IReportModel } from "./IReportModel";
import { ReportType } from "./ReportType";
import { formatDate } from "@/filters/mwTableFormatters";
import DateRangeFilter from "@/components/mwUtils/mwTable/Filters/DateRangeFilter";
import { nameof } from "@/utils/utils";
import VandanlaegVirksomhedsTypeFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/VandanlaegVirksomhedsTypeFilter";
import JupiteranlaegsartFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiteranlaegsartFilter";
import JupiterAktivStatusFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/JupiterAktivStatusFilter";
import HasValueFilter from "@/components/mwUtils/mwTable/Filters/SelectFilters/HasValueFilter";

export class VandOppumpetReportModel implements IReportModel<VandOppumpetReportModel> {
  aarstal?: number;
  maaned?: number;
  startdato?: Date;
  slutdato?: Date;
  anlaegStartdato?: Date;
  anlaegSlutdato?: Date;
  anlaegsnavn?: string;
  anlaegsadresse?: string;
  cvrNr?: string;
  pnr?: string;
  primaerVirksomhedstype?: string;
  primaerVirksomhedstypeId?: string;
  oevrigeVirksomhedstyper?: string;
  anlaegsnr?: string;
  anlaegJupiterId?: number;
  anlaegsart?: string;
  anlaegsartId?: string;
  jupiterAktivStatus?: string;
  jupiterAktivStatusId?: string;
  grundvand?: number;
  overfladevand?: number;
  maalerstandStart?: number;
  maalerstandSlut?: number;
  omregningsfaktor?: number;
  totalGrundvand?: number;
  totalOverfladevand?: number;
  antal?: number;

  get columnModel(): IMWReportColumn<VandOppumpetReportModel>[] {
    return [
      { id: "aarstal", label: "Årstal", show: true },
      { id: "maaned", label: "Måned", show: true },
      { id: "startdato", label: "Startdato", show: true, formatter: formatDate },
      { id: "slutdato", label: "Slutdato", show: false, formatter: formatDate },
      { id: "anlaegStartdato", label: "Anlæg startdato", show: false, formatter: formatDate },
      { id: "anlaegSlutdato", label: "Anlæg slutdato", show: false, formatter: formatDate },
      { id: "anlaegsnavn", label: "Anlægsnavn", show: false },
      { id: "anlaegsadresse", label: "Anlægsadresse", show: false },
      { id: "cvrNr", label: "CVR-nummer", show: false },
      { id: "pnr", label: "P-nummer", show: false },
      { id: "primaerVirksomhedstype", label: "Primær virksomhedstype", show: false },
      { id: "oevrigeVirksomhedstyper", label: "Øvrige virksomhedstyper", show: true, sort: false },
      { id: "anlaegsnr", label: "Anlægsnummer", show: false },
      { id: "anlaegJupiterId", label: "Jupiter ID", show: true },
      { id: "anlaegsart", label: "Anlægsart", show: true },
      { id: "jupiterAktivStatus", label: "Anlæg aktivstatus", show: true },
      { id: "grundvand", label: "Oppumpet mængde grundvand m3", show: false },
      { id: "overfladevand", label: "Oppumpet mængde overfladevand m3", show: false },
      { id: "maalerstandStart", label: "Målerstart", show: false },
      { id: "maalerstandSlut", label: "Målerslut", show: false },
      { id: "omregningsfaktor", label: "Omregningsfaktor", show: false },
      { id: "totalGrundvand", label: "Sum oppumpet mængde grundvand m3", show: true },
      { id: "totalOverfladevand", label: "Sum oppumpet mængde overfladevand m3", show: true },
      { id: "antal", label: "Antal", show: true }
    ];
  }
  get endpoint(): string {
    return `rapport/${this.reportType}`;
  }
  get filters(): IMwTableFilter[] {
    return [
      new DateRangeFilter(nameof<VandOppumpetReportModel>("startdato"), "Dato interval", "Dato interval"),
      new JupiteranlaegsartFilter(nameof<VandOppumpetReportModel>("anlaegsartId"), "Anlægsart", "Anlægsart"),
      new VandanlaegVirksomhedsTypeFilter(
        nameof<VandOppumpetReportModel>("primaerVirksomhedstypeId"),
        "Primær virksomhedstype",
        "Primær virksomhedstype"
      ),
      new JupiterAktivStatusFilter(nameof<VandOppumpetReportModel>("jupiterAktivStatusId"), "Anlæg aktivstatus", "Anlæg aktivstatus"),
      new VandanlaegVirksomhedsTypeFilter("oevrigeVirksomhedstyperList", "Øvrige virksomhedstyper", "Øvrige virksomhedstyper"),
      new HasValueFilter(
        nameof<VandOppumpetReportModel>("grundvand"),
        "Grundvand m3 angivet",
        "Grundvand m3 angivet",
        "Angivet",
        "Ikke angivet"
      ),
      new HasValueFilter(
        nameof<VandOppumpetReportModel>("overfladevand"),
        "Overfladevand m3 angivet",
        "Overfladevand m3 angivet",
        "Angivet",
        "Ikke angivet"
      )
    ];
  }
  get reportType(): ReportType {
    return ReportType.VandOppumpet;
  }
}
