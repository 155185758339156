import moment from "moment";

export class LogItem {
  emne: string = '';
  notat: string = '';
  id: string = '';
  brugerId: string = '';
  bruger: string = '';
  hovering: boolean = false;
  editMode: boolean = false;
  dato: moment.Moment | null = null;
  virksomhedId: string = '';
  constructor(input: {
    emne?: string;
    notat: string;
    brugerId?: string;
    dato?: moment.Moment;
    virksomhedId: string;
    id: string;
    bruger: string;
  }) {
    this.emne = input.emne ? input.emne : '';
    this.id = input.id ? input.id : '';
    this.notat = input.notat ? input.notat : '';
    this.bruger = input.bruger ? input.bruger : '';
    this.virksomhedId = input.virksomhedId ? input.virksomhedId : '';
    this.brugerId = input.brugerId ? input.brugerId : '';
    this.dato = input.dato ? input.dato : null;
    this.hovering = false;
    this.editMode = false;
  }
}
