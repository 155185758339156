import { HttpFileResponse, HttpResponse } from "./interfaces";
import { parseFilenameFromContentDisposition } from "@/utils/networking/httputils";
import { formatDate } from "@/utils/formatting";
import { useSessionStore } from "@/stores/session";
import { useMainStore } from "@/stores/main";
import moment from "moment";

// TODO: Shouldn't export the base URL as all interaction with the API must happen through the http abstraction layer.
export const API_URL = import.meta.env.VITE_MW_API_URL;

interface HttpOptions {
  disableMoment?: boolean;
  fetchOptions?: RequestInit;
}

async function handleResponse(response: Response, httpOptions: HttpOptions): Promise<HttpResponse> {
  let responseData = {};

  const responseContentType = response.headers.get("Content-Type");
  if (responseContentType?.includes("json")) {
    responseData = await response.json();
  } else if (responseContentType?.includes("text/plain")) {
    responseData = await response.text();
  }

  const result: HttpResponse = {
    status: response.status,
    data: responseData,
    isError: !response.ok
  };

  if (httpOptions?.disableMoment !== true) {
    const retval = JSON.parse(JSON.stringify(result.data), formatDate);
    result.data = retval;
  }

  return result;
}

async function handleFileResponse(response: Response): Promise<HttpResponse> {
  const blob = await response.blob();

  const result: HttpResponse = {
    status: response.status,
    data: blob,
    isError: !response.ok,
    headers: response.headers
  };

  return result;
}

async function handleResponseError(response: any): Promise<HttpResponse> {
  let message = await response.text();
  try {
    message = await JSON.parse(message);
  } catch {
    //Do nothing
  }

  if (response.headers.get("mwtokenexpired") === "true") {
    const requestedRoute = localStorage.requestedRoute;
    localStorage.setItem("goToRouteAfterLogin", requestedRoute);

    const sessionStore = useSessionStore();
    sessionStore.logoutUser();
  }
  // login skal ikke vise errorModal fordi den har sin egen fejlhåndtering.
  else if (!response.url.includes("/api/login")) {
    const mainStore = useMainStore();
    const errorResponse = { isError: true, data: message, status: response.status };

    if (response.status === 403) {
      mainStore.openForbiddenErrorModal(errorResponse);
    } else {
      mainStore.openHttpErrorModal(errorResponse);
    }
  }

  return {
    isError: true,
    data: message,
    status: response.status
  };
}

function createFetchOptions(fetchOptions: RequestInit = {}): RequestInit {
  const defaultFetchOptions: RequestInit = {
    credentials: "same-origin",
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };

  const newFetchOptions = Object.assign(defaultFetchOptions, fetchOptions);

  const usertoken = localStorage.getItem("usertoken");
  if (usertoken) {
    newFetchOptions.headers["Authorization"] = `Bearer ${usertoken}`;
  }

  return newFetchOptions;
}

async function standardFetch(url: string, httpOptions: HttpOptions, isFile = false): Promise<HttpResponse> {
  const fetchOptions = createFetchOptions(httpOptions.fetchOptions);

  try {
    const newUrl = new URL(url, API_URL);
    const response = await fetch(newUrl, fetchOptions);

    if (response.ok) {
      if (isFile) {
        return await handleFileResponse(response);
      }

      return await handleResponse(response, httpOptions);
    } else {
      return await handleResponseError(response);
    }
  } catch (error: any) {
    const errorResponse = {
      isError: true,
      status: 500,
      data: error
    };

    // Vis kun fejlbesked hvis kaldet ikke er blevet aflyst
    if (errorResponse && errorResponse.data && errorResponse.data.name !== "AbortError") {
      const mainStore = useMainStore();
      mainStore.openHttpErrorModal(errorResponse);
    }

    return errorResponse;
  }
}

function createHttpOptions(fetchOptions: RequestInit, httpOptions: HttpOptions = {}) {
  if (httpOptions.fetchOptions) {
    httpOptions.fetchOptions = Object.assign(httpOptions.fetchOptions, fetchOptions);
  } else {
    httpOptions.fetchOptions = fetchOptions;
  }

  return httpOptions;
}

// Because momentjs and asp.net handles dates differently depending on daylight saving time (DST)
// I've made a replacer to handle the dates, and always send them as local timezones, without UTC or offset
function handleDatetimeOffset(this: any, key: any, value: any): any {
  if (moment.isMoment(this[key])) {
    const date = moment(this[key]);
    const offset = date.utcOffset();
    return date
      .add(offset, "minute") //Offset the time by the registered offset, because toISOString deducts the original offset
      .toISOString() //Gets ISO8601 string, where the timezome always is UTC
      .replace(/Z$/g, ""); //Remove trailing "Z" because it indicates UTC
  }
  return value;
}

export async function fetchGet(url: string, options?: HttpOptions) {
  const fetchOptions: RequestInit = {
    method: "GET"
  };

  const newOptions = createHttpOptions(fetchOptions, options);
  return await standardFetch(url, newOptions);
}

export async function fetchPost(url: string, payload: any, options?: HttpOptions, isFile?: boolean): Promise<HttpResponse> {
  const fetchOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify(payload, handleDatetimeOffset)
  };

  const newOptions = createHttpOptions(fetchOptions, options);

  return await standardFetch(url, newOptions, isFile);
}

export async function fetchPut(url: string, payload: any, options?: HttpOptions): Promise<HttpResponse> {
  const fetchOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify(payload, handleDatetimeOffset)
  };

  const newOptions = createHttpOptions(fetchOptions, options);

  return await standardFetch(url, newOptions);
}

export async function fetchDelete(url: string, options?: HttpOptions): Promise<HttpResponse> {
  const fetchOptions: RequestInit = {
    method: "DELETE"
  };

  const newOptions = createHttpOptions(fetchOptions, options);

  return await standardFetch(url, newOptions);
}

export async function fetchDownloadPost(url: string, payload: any, options?: HttpOptions, isFile?: boolean): Promise<HttpFileResponse> {
  const { status, data, isError, headers } = await fetchPost(url, payload, options, isFile);

  const contentDisposition = headers?.get("Content-Disposition");
  const contentType = headers?.get("Content-Type");

  const filename: string = contentDisposition ? parseFilenameFromContentDisposition(contentDisposition) : "fejl";
  const mimeType: string = contentType ?? "text/plain";

  return { status, data, filename, mimeType, isError };
}

export async function fetchDownload(url: string, options?: HttpOptions): HttpFileResponse {
  // const config: AxiosRequestConfig = {
  //   responseType: "arraybuffer",
  //   headers: {
  //     Accept: "application/octet-stream"
  //   }
  // };

  const fetchOptions: RequestInit = {
    method: "GET"
  };

  const newOptions = createHttpOptions(fetchOptions, options);
  const { status, data, isError, headers } = await standardFetch(url, newOptions, true);

  // const { status, data, isError, headers } = await fetchGet(url, options);

  const contentDisposition = headers?.get("Content-Disposition");
  const contentType = headers?.get("Content-Type");

  const filename: string = contentDisposition ? parseFilenameFromContentDisposition(contentDisposition) : "fejl";
  const mimeType: string = contentType ?? "text/plain";

  return { status, data, filename, mimeType, isError };
}

export async function fetchUpload(url: string, payload: FormData): Promise<HttpResponse> {
  const fetchOptions: RequestInit = {
    method: "POST",
    body: payload,
    credentials: "same-origin",
    headers: {} //Bemærk at fetchUpload ikke skal have nogen Content-Type. https://stackoverflow.com/a/39281156
  };
  const newOptions = createHttpOptions(fetchOptions);
  return await standardFetch(url, newOptions);
}
